import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/Navbar.css'
import BarChartSharpIcon from '@mui/icons-material/BarChartSharp';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import Logo from '../assets/Immagine_2023-09-24_162522-removebg-preview.png'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

function Navbar() {
  const [isChartPopupOpen, setChartPopupOpen] = useState(false);
    const [isPlayPopupOpen, setPlayPopupOpen] = useState(false);
    const [isHelpPopupOpen, setHelpPopupOpen] = useState(false);

    const toggleChartPopup = () => {
        setChartPopupOpen(prevState => !prevState);
    };

    const togglePlayPopup = () => {
        setPlayPopupOpen(prevState => !prevState);
    };

    const toggleHelpPopup = () => {
        setHelpPopupOpen(prevState => !prevState);
    };
  return (
    <div className='navbar'>
        <div className='leftSide' style={{backgroundImage: `url(${Logo})`}}>
          <Link to="/">
            <h1 style={{color: "transparent"}}>Curryosity</h1>
          </Link>
        </div>
        <div className='rightSide'>
          <Link to = "/">
            <HomeRoundedIcon />
            </Link>
            <PlayLessonOutlinedIcon onClick={togglePlayPopup} />
            <HelpOutlineIcon onClick={toggleHelpPopup} />
            {isChartPopupOpen && (
              <div onClick={toggleChartPopup} className='popup-overlay'>
                    <div className='popup'>
                        {<p>Stats</p>}
                    </div>
                  </div>
                )}

                {isPlayPopupOpen && (
                  <div onClick={togglePlayPopup} className='popup-overlay'>
                    <div className='popup'>
                        {<div><p className='spiegazione'>The game is really simple but make sure you understand it before you start to play in order to avoid misunderstandings.<br></br>
                          The game’s main goal is, in every mode, to reach the highest score possible by exploiting your nba knowledge.<br></br>
                          There are 4 modes and each one works differently. <br></br> <br></br>
                          So which one do you want to play?</p><br></br>
                          <Link to="/preveasy"><button className='bottoniPop'>LeGuess the team</button></Link><Link to="/prevmedium"><button className='bottoniPop'>Top 75 Players of All Time</button></Link><Link to="/prevcareer"><button className='bottoniPop'>LeGuess the Player</button></Link><Link to="/prevnickname"><button className='bottoniPop'>Historical Nicknames</button></Link>
                          </div>}
                    </div>
                  </div>
                )}

                {isHelpPopupOpen && (
                  <div onClick={toggleHelpPopup} className='popup-overlay'>
                    <div className='popup'>
                        {<p className='spiegazione'>To consider a player in the game some conditions are required:<br></br>
                        1) The player must have played at least one regular season match<br></br>
                        2) Unless impossibilities given by injuries, the player must have played at least one match in 2022-2023 regular season<br></br>
                        3) To be considered on a specific team, the player must have had at least one regular season match for that team<br></br>
                        4) The website is updated at 10/22/2023<br></br><br></br>
                          Feel free to report any kind of issue.<br></br>
                          We are here to give you the best version of the site and we are happy to fix any sort of mistake or problem. <br></br>
                          Please contact us at: curryosity.infomail@gmail.com</p>}
                    </div>
                  </div>
                )}
        </div>
    </div>
  )
}

export default Navbar