import React from "react";
import { topPlayers } from "../topPlayers";
import "../styles/Easy.css";
import { useState, useEffect, useRef } from "react";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsBasketballTwoToneIcon from '@mui/icons-material/SportsBasketballTwoTone';
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';
import James from '../assets/James.jpg'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import giphy from '../assets/giphy (1).gif'
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import livello6 from '../assets/livello6.jpg'
import livello5 from '../assets/livello5.jpg'
import livello4 from '../assets/livello4.jpg'
import livello3 from '../assets/livello3.jpg'
import livello2 from '../assets/livello2.jpg'
import livello1 from '../assets/livello1.jpg'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link } from "react-router-dom";


/**
 * @typedef {Object} Player
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} img
 * @property {string[]} teams
 * @property {string} level
 */

function Medium() {
  const x = Math.floor(Math.random() * topPlayers.length);
  const [randomPlayer, setRandomPlayer] = useState(null);
  const numbers = [];
  const [randomArrayPlayers, setRandomArrayPlayers] = useState([])
  const [position, setPosition] = useState(0)
  const numeri = []
  const [randomArrayPhoto, setRandomArrayPhoto] = useState([])

  useEffect(() => {
    const randomPlayer = topPlayers[x];
    setRandomPlayer(randomPlayer);
    while (numbers.length < 10) {
      const randomNumber = Math.floor(Math.random() * topPlayers.length);
      const randomPlayer = topPlayers[randomNumber];
      if (!numbers.includes(randomPlayer)) {
        numbers.push(randomPlayer);
        numeri.push(randomPlayer.img)
      }
    }
    console.log(numbers)
    console.log(numeri)
    setRandomArrayPlayers(numbers)
    setRandomArrayPhoto(numeri)
  }, []);

  const [squadreIndovinate1, setSquadreIndovinate1] = useState([]);
  const [squadreIndovinate2, setSquadreIndovinate2] = useState([]);
  const [squadreIndovinate3, setSquadreIndovinate3] = useState([]);
  const [squadreIndovinate4, setSquadreIndovinate4] = useState([]);
  const [squadreIndovinate5, setSquadreIndovinate5] = useState([]);
  const [squadreIndovinate6, setSquadreIndovinate6] = useState([]);
  const [squadreIndovinate7, setSquadreIndovinate7] = useState([]);
  const [squadreIndovinate8, setSquadreIndovinate8] = useState([]);
  const [squadreIndovinate9, setSquadreIndovinate9] = useState([]);
  const [squadreIndovinate10, setSquadreIndovinate10] = useState([]);

  const inputRef = useRef(null);




  const [completedPlayers, setCompletedPlayers] = useState(0);
  
  const [squadreGiàInserite1, setSquadreGiàInserite1] = useState([]);
  const [squadreGiàInserite2, setSquadreGiàInserite2] = useState([]);
  const [squadreGiàInserite3, setSquadreGiàInserite3] = useState([]);
  const [squadreGiàInserite4, setSquadreGiàInserite4] = useState([]);
  const [squadreGiàInserite5, setSquadreGiàInserite5] = useState([]);
  const [squadreGiàInserite6, setSquadreGiàInserite6] = useState([]);
  const [squadreGiàInserite7, setSquadreGiàInserite7] = useState([]);
  const [squadreGiàInserite8, setSquadreGiàInserite8] = useState([]);
  const [squadreGiàInserite9, setSquadreGiàInserite9] = useState([]);
  const [squadreGiàInserite10, setSquadreGiàInserite10] = useState([]);


  const [correctGuesses, setCorrectGuesses] = useState(0);
  const [wrongGuesses, setWrongGuesses] = useState(0);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  const [remainingHearts, setRemainingHearts] = useState(3);

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);


  const squadreDisponibili = [
    "Atlanta Hawks",
    "Boston Celtics",
    "Brooklyn Nets",
    "Charlotte Hornets",
    "Chicago Bulls",
    "Cleveland Cavaliers",
    "Dallas Mavericks",
    "Denver Nuggets",
    "Detroit Pistons",
    "Golden State Warriors",
    "Houston Rockets",
    "Indiana Pacers",
    "Los Angeles Clippers",
    "Los Angeles Lakers",
    "Memphis Grizzlies",
    "Miami Heat",
    "Milwaukee Bucks",
    "Minnesota Timberwolves",
    "New Orleans Pelicans",
    "New York Knicks",
    "Oklahoma City Thunder",
    "Orlando Magic",
    "Philadelphia 76ers",
    "Phoenix Suns",
    "Portland Trail Blazers",
    "Sacramento Kings",
    "San Antonio Spurs",
    "Toronto Raptors",
    "Utah Jazz",
    "Washington Wizards"
  ];

  const [inputValue, setInputValue] = useState("");
  const [squadreSuggerite, setSquadreSuggerite] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [silverSkipper, setSilverSkipper] = useState(true)
  const [counterSilver, setCounterSilver] = useState(2)
  const [goldenSkipper, setGoldenSkipper] = useState(false)
  const [counterGolden, setCounterGolden] = useState(1)
  const [dp, setDp] = useState("none");

  const [gameOverTimer, setGameOverTimer] = useState(null);
  const [countdown, setCountdown] = useState(120); // 2 minuti iniziali (in secondi)


  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertVisible(true);
  
    // Imposta un timeout per nascondere l'avviso dopo un certo periodo di tempo (ad esempio, 3 secondi)
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000); // Nascondi l'avviso dopo 3 secondi (puoi regolare questo valore)
  };

  function CustomAlert({ message, visible }) {
    return visible ? (
      <div className="custom-alert">
        <p>{message}</p>
      </div>
    ) : null;
  }

  const [verificaMessages1, setVerificaMessages1] = useState([]);
  const [verificaMessages2, setVerificaMessages2] = useState([]);
  const [verificaMessages3, setVerificaMessages3] = useState([]);
  const [verificaMessages4, setVerificaMessages4] = useState([]);
  const [verificaMessages5, setVerificaMessages5] = useState([]);
  const [verificaMessages6, setVerificaMessages6] = useState([]);
  const [verificaMessages7, setVerificaMessages7] = useState([]);
  const [verificaMessages8, setVerificaMessages8] = useState([]);
  const [verificaMessages9, setVerificaMessages9] = useState([]);
  const [verificaMessages10, setVerificaMessages10] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);

    const squadreFiltrate = squadreDisponibili.filter((squadra) => {
      const squadraFormattata = squadra.toLowerCase();
      const paroleSquadra = squadraFormattata.split(" ");
      const valoreLowerCase = value.toLowerCase();

      if (squadraFormattata.startsWith(valoreLowerCase)){
        return true
      }

      // Controlla se la seconda parola della squadra inizia con il valore di ricerca
      if (
        paroleSquadra.length > 1 &&
        paroleSquadra[1].startsWith(valoreLowerCase)
      ) {
        return true;
      }

      if (
        paroleSquadra.length > 2 &&
        paroleSquadra[2].startsWith(valoreLowerCase)
      ) {
        return true;
      }

      if (paroleSquadra[0].startsWith(valoreLowerCase)) {
        return true;
      }

      return false;
    });

    setSquadreSuggerite(squadreFiltrate);
    setSelectedSuggestionIndex(-1);
    setShowDropdown(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex < squadreSuggerite.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedSuggestionIndex !== -1) {
        handleSquadraClick(squadreSuggerite[selectedSuggestionIndex]);
      }
    }
  };

  const handleSquadraClick = (squadra) => {
    setInputValue(squadra);
    setShowDropdown(false);
  };

  /**
   * Funzione per verificare i dettagli di un giocatore
   * @param {string} squadra - Il nome della squadra
   * @param {Player} giocatore - L'oggetto giocatore che contiene i dettagli del giocatore
   */
  

  const verifica1 = (squadra, giocatore) => {
    if (squadreGiàInserite1.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite1.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages1([...verificaMessages1, message]);
      setSquadreGiàInserite1([...squadreGiàInserite1, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages1.includes(message)
    ){
      setVerificaMessages1([...verificaMessages1, message])
    }else{
      showAlert("this is not a correct team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate1.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate1([...squadreIndovinate1, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica2 = (squadra, giocatore) => {
    if (squadreGiàInserite2.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite2.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages2([...verificaMessages2, message]);
      setSquadreGiàInserite2([...squadreGiàInserite2, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages2.includes(message)
    ){
      setVerificaMessages2([...verificaMessages2, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate2.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate2([...squadreIndovinate2, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica3 = (squadra, giocatore) => {
    if (squadreGiàInserite3.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite3.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages3([...verificaMessages3, message]);
      setSquadreGiàInserite3([...squadreGiàInserite3, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages3.includes(message)
    ){
      setVerificaMessages3([...verificaMessages3, message])
    }else{
      showAlert("this is not a correct team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate3.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate3([...squadreIndovinate3, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica4 = (squadra, giocatore) => {
    if (squadreGiàInserite4.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite4.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages4([...verificaMessages4, message]);
      setSquadreGiàInserite4([...squadreGiàInserite4, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages4.includes(message)
    ){
      setVerificaMessages4([...verificaMessages4, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate4.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate4([...squadreIndovinate4, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica5 = (squadra, giocatore) => {
    if (squadreGiàInserite5.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite5.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages5([...verificaMessages5, message]);
      setSquadreGiàInserite5([...squadreGiàInserite5, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages5.includes(message)
    ){
      setVerificaMessages5([...verificaMessages5, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate5.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate5([...squadreIndovinate5, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica6 = (squadra, giocatore) => {
    if (squadreGiàInserite6.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite6.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages6([...verificaMessages6, message]);
      setSquadreGiàInserite6([...squadreGiàInserite6, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages6.includes(message)
    ){
      setVerificaMessages6([...verificaMessages6, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate6.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate6([...squadreIndovinate6, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica7 = (squadra, giocatore) => {
    if (squadreGiàInserite7.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite7.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages7([...verificaMessages7, message]);
      setSquadreGiàInserite7([...squadreGiàInserite7, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages7.includes(message)
    ){
      setVerificaMessages7([...verificaMessages7, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate7.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate7([...squadreIndovinate7, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica8 = (squadra, giocatore) => {
    if (squadreGiàInserite8.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite8.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages8([...verificaMessages8, message]);
      setSquadreGiàInserite8([...squadreGiàInserite8, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages8.includes(message)
    ){
      setVerificaMessages8([...verificaMessages8, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate8.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate8([...squadreIndovinate8, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica9 = (squadra, giocatore) => {
    if (squadreGiàInserite9.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite9.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages9([...verificaMessages9, message]);
      setSquadreGiàInserite9([...squadreGiàInserite9, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages9.includes(message)
    ){
      setVerificaMessages9([...verificaMessages9, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate9.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate9([...squadreIndovinate9, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const verifica10 = (squadra, giocatore) => {
    if (squadreGiàInserite10.includes(squadra)) {
      showAlert("You have already inserted this team!");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span>
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span>
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (!squadreGiàInserite10.includes(squadra) &&
      squadreDisponibili.includes(squadra)) {
      setVerificaMessages10([...verificaMessages10, message]);
      setSquadreGiàInserite10([...squadreGiàInserite10, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages10.includes(message)
    ){
      setVerificaMessages10([...verificaMessages10, message])
    }else{
      showAlert("this is not an available team")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate10.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate10([...squadreIndovinate10, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }
  };

  const [color1, setColor1] = useState("red")
  const [color2, setColor2] = useState("red")
  const [color3, setColor3] = useState("red")
  const [color4, setColor4] = useState("red")
  const [color5, setColor5] = useState("red")
  const [color6, setColor6] = useState("red")
  const [color7, setColor7] = useState("red")
  const [color8, setColor8] = useState("red")
  const [color9, setColor9] = useState("red")
  const [color10, setColor10] = useState("red")

  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate10.length === randomArrayPlayers[9].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(0)
      setCompletedPlayers(completedPlayers+1)
      setDisabled10(true)
      setColor10("green")
    }
  }, [squadreIndovinate10])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate1.length === randomArrayPlayers[0].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(1)
      setCompletedPlayers(completedPlayers+1)
      setDisabled1(true)
      setColor1("green")
    }
  }, [squadreIndovinate1])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate2.length === randomArrayPlayers[1].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(2)
      setCompletedPlayers(completedPlayers+1)
      setDisabled2(true)
      setColor2("green")
    }
  }, [squadreIndovinate2])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate3.length === randomArrayPlayers[2].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(3)
      setCompletedPlayers(completedPlayers+1)
      setDisabled3(true)
      setColor3("green")
    }
  }, [squadreIndovinate3])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate4.length === randomArrayPlayers[3].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(4)
      setCompletedPlayers(completedPlayers+1)
      setDisabled4(true)
      setColor4("green")
    }
  }, [squadreIndovinate4])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate5.length === randomArrayPlayers[4].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(5)
      setCompletedPlayers(completedPlayers+1)
      setDisabled5(true)
      setColor5("green")
    }
  }, [squadreIndovinate5])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate6.length === randomArrayPlayers[5].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(6)
      setCompletedPlayers(completedPlayers+1)
      setDisabled6(true)
      setColor6("green")
    }
  }, [squadreIndovinate6])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate7.length === randomArrayPlayers[6].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(7)
      setCompletedPlayers(completedPlayers+1)
      setDisabled7(true)
      setColor7("green")
    }
  }, [squadreIndovinate7])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate8.length === randomArrayPlayers[7].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(8)
      setCompletedPlayers(completedPlayers+1)
      setDisabled8(true)
      setColor8("green")
    }
  }, [squadreIndovinate8])
  useEffect(() => {
    if (randomArrayPlayers[position] && squadreIndovinate9.length === randomArrayPlayers[8].teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setPosition(9)
      setCompletedPlayers(completedPlayers+1)
      setDisabled9(true)
      setColor9("green")
    }
  }, [squadreIndovinate9])

  

  useEffect(() => {
    if (wrongGuesses == 3) {
      setGameOver(true);
      setRemainingHearts(3 - wrongGuesses)
    } else {
      setRemainingHearts(3 - wrongGuesses)
    }
  }, [wrongGuesses]);

  const handleButtonClick = () => {
    if (position == 0 && randomArrayPlayers[position].teams.length != squadreIndovinate1.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate1.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica1(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 1 && randomArrayPlayers[position].teams.length != squadreIndovinate2.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate2.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica2(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 2 && randomArrayPlayers[position].teams.length != squadreIndovinate3.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate3.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica3(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 3 && randomArrayPlayers[position].teams.length != squadreIndovinate4.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate4.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica4(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 4 && randomArrayPlayers[position].teams.length != squadreIndovinate5.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate5.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica5(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 5 && randomArrayPlayers[position].teams.length != squadreIndovinate6.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate6.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica6(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 6 && randomArrayPlayers[position].teams.length != squadreIndovinate7.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate7.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica7(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 7 && randomArrayPlayers[position].teams.length != squadreIndovinate8.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate8.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica8(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 8 && randomArrayPlayers[position].teams.length != squadreIndovinate9.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate9.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica9(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }else if (position == 9 && randomArrayPlayers[position].teams.length != squadreIndovinate10.length){
      if (randomArrayPlayers[position].teams.length === squadreIndovinate10.length){
        showAlert("You already find all the teams")
      }
      if (inputValue.trim() !== "") {
        verifica10(inputValue, randomArrayPlayers[position]);
        setInputValue("");
      }
    }
    setInputValue("");
  };

  const [disabled1, setDisabled1] = useState(false)
  const [disabled2, setDisabled2] = useState(false)
  const [disabled3, setDisabled3] = useState(false)
  const [disabled4, setDisabled4] = useState(false)
  const [disabled5, setDisabled5] = useState(false)
  const [disabled6, setDisabled6] = useState(false)
  const [disabled7, setDisabled7] = useState(false)
  const [disabled8, setDisabled8] = useState(false)
  const [disabled9, setDisabled9] = useState(false)
  const [disabled10, setDisabled10] = useState(false)

  const handleNextPlayerClick1 = () => {
    setPosition(0)
  }
  const handleNextPlayerClick2 = () => {
    setPosition(1)
  }
  const handleNextPlayerClick3 = () => {
    setPosition(2)
  }
  const handleNextPlayerClick4 = () => {
    setPosition(3)
  }
  const handleNextPlayerClick5 = () => {
    setPosition(4)
  }
  const handleNextPlayerClick6 = () => {
    setPosition(5)
  }
  const handleNextPlayerClick7 = () => {
    setPosition(6)
  }
  const handleNextPlayerClick8 = () => {
    setPosition(7)
  }
  const handleNextPlayerClick9 = () => {
    setPosition(8)
  }
  const handleNextPlayerClick10 = () => {
    setPosition(9)
  }

  const handleNextPlayerClickPrev = () => {
    if (position == 0){
      setPosition(9)
    }else{
      setPosition(v => v-1);
    }
  }

  const handleNextPlayerClickGolden = () => {
    setCorrectGuesses(0);
    setCurrentStreak(0);
    if (position == 9){
      setPosition(0)
    }else{
      setPosition(v => v+1);
    }
    setSilverSkipper(true)
    //setGoldenSkipper(true)
    setCounterGolden(0)
  };

  const [howtoplay, setHowtoplay] = useState(false)
  const [gameStarted, setGameStarted] = useState(false)

  const [timerPaused, setTimerPaused] = useState(false);
  const [pausedTime, setPausedTime] = useState(null);

  const [win, setWin] = useState(false)

  useEffect(() => {
    if (completedPlayers === 10){
      setWin(true)
      setTimerPaused(true)
    }
  },[completedPlayers])


  const htpChange = () => {
    setHowtoplay(false)
    setGameStarted(true)
  }

  useEffect(() => {
    if (gameStarted && !timerPaused) {
      const timer = setInterval(() => {
        // Riduci il countdown di 1 secondo
        setCountdown((prevCountdown) => prevCountdown - 1);
  
        // Controlla se il countdown è arrivato a 0
        if (countdown === 0) {
          setGameOver(true);
          clearInterval(timer); // Arresta il timer quando il countdown raggiunge 0
        }
      }, 1000); // Ogni 1 secondo
  
      // Cleanup: Assicurati di cancellare il timer quando il componente viene smontato o il gioco finisce
      return () => {
        clearInterval(timer);
      };
    } else if (gameStarted && timerPaused) {
      // Salva il tempo rimanente quando il timer viene messo in pausa
      setPausedTime(countdown);
    }
  }, [gameStarted, countdown, timerPaused]);

  const toggleTimer = () => {
    if (timerPaused) {
      // Riprendi il timer dalla pausa
      setTimerPaused(false);
      // Imposta il countdown con il tempo rimanente
      setCountdown(pausedTime);
    } else {
      // Metti in pausa il timer
      setTimerPaused(true);
    }
  };
  
  const [finalLevel, setFinalLevel] = useState("")
  const [finalLevelURL, setFinalLevelURL] = useState()

  useEffect(() => {
    if (completedPlayers<2){
      setFinalLevel("Oops you just missed a layup")
      setFinalLevelURL(livello1)
    } else if (completedPlayers>=2 && completedPlayers<4){
      setFinalLevel("Naah you can't brick an open three like this")
      setFinalLevelURL(livello2)
    }else if (completedPlayers>=4 && completedPlayers<7){
      setFinalLevel("You had a solid game, keep it up")
      setFinalLevelURL(livello3)
    }else if (completedPlayers>=7 && completedPlayers<9){
      setFinalLevel("You are splashing threes like Stephen Curry man")
      setFinalLevelURL(livello4)
    }else if (completedPlayers>=9 && completedPlayers<10){
      setFinalLevel("Congratulations, you are the LeBron James of our site")
      setFinalLevelURL(livello5)
    }else{
      setFinalLevel("You are just the G.O.A.T")
      setFinalLevelURL(livello6)
    }
  },[completedPlayers])


  const [infoPrev, setInfoPrev] = useState(true)
  const [info, setInfo] = useState(false)

  const changeInfo = () => {
    setInfo(!info)
    setTimerPaused(true);
  }

  const changeInfoPrev = () => {
    setInfoPrev(!infoPrev)
    setHowtoplay(true)
  }

  const refreshPage = () => {
    window.location.reload(false);
  }

  return (
    <div>
      <Navbar></Navbar>
      <div className="easy">
      {howtoplay && (
                <div className='popup-overlay'>
                    <div className='popup'>
                        {<div><p className="spiegazione">You have 2 minutes, are you ready?</p><br></br><button className="bottoneHow" onClick={htpChange}>Start</button></div>}
                    </div>
                </div>
                )}
        {gameOver && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div className="fine"><div className="cry"><img className="cryImg" src={finalLevelURL} /></div>
                <div className="testoFine">
                <p className="spiegazione">{finalLevel}. <strong>Your final score is {completedPlayers}/10</strong></p>
                </div>
                <button className="bottoneHow1" onClick={refreshPage}>New Game</button><button style={{color: "transparent", backgroundColor: "transparent", cursor: "default"}}>OMG an easter egg</button>
                <Link to="/"><button className='bottoneHow2'><HomeRoundedIcon /><br></br>Home</button></Link></div>}
            </div>
          </div>
        )}
        {win && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div><div className="cry"><img className="cryImg" src={livello6} /></div>
                <div className="testoFine">
                <p className="spiegazione">Congratulations! You found all the teams.<br></br>
                <strong>You're simply the G.O.A.T.</strong>
                </p>
                </div>
                <button className="bottoneHow1" onClick={refreshPage}>New Game</button><button style={{color: "transparent", backgroundColor: "transparent", cursor: "default"}}>OMG an easter egg</button>
                <Link to="/"><button className='bottoneHow2'><HomeRoundedIcon /><br></br>Home</button></Link>
                </div>}
            </div>
          </div>
        )}
        {info && (
          <div className='popup-overlay' onClick={changeInfo}>
            <div className='popup'>
              <div className="squadreStoriche">
                <p className="squadreStoriche1">1. <strong>Atlanta Hawks</strong>: <br></br>
                    - St. Louis Hawks (1955–1968) <br></br>
                    - Atlanta Hawks (1968-today) <br></br>

                  2. <strong>Boston Celtics</strong>: <br></br>
                    - Boston Celtics (1946–today) <br></br>

                  3. <strong>Brooklyn Nets</strong>: <br></br>
                    - New Jersey Americans (1967–1968) <br></br>
                    - New York Nets (1968–1977) <br></br>
                    - New Jersey Nets (1977–2012) <br></br>
                    - Brooklyn Nets (2012-today) <br></br>

                  4. <strong>Charlotte Hornets</strong>:<br></br>
                    - Charlotte Hornets (1988–2002) <br></br>
                    - Charlotte Bobcats (2004–2014)<br></br>
                    - Charlotte Hornets (2014–today)<br></br>

                  5. <strong>Chicago Bulls</strong>:<br></br>
                    - Chicago Bulls (1966–today)<br></br>

                  6. <strong>Cleveland Cavaliers</strong>:<br></br>
                    - Cleveland Cavaliers (1970–today)<br></br>

                  7. <strong>Dallas Mavericks</strong>:<br></br>
                    - Dallas Mavericks (1980–today)<br></br>

                  8. <strong>Denver Nuggets</strong>:<br></br>
                    - Denver Nuggets (1967–today)<br></br>

                  9. <strong>Detroit Pistons</strong>:<br></br>
                    - Fort Wayne Pistons (1941–1957)<br></br>
                    - Detroit Pistons (1957–today)<br></br>

                  10. <strong>Golden State Warriors</strong>:<br></br>
                      - Philadelphia Warriors (1946–1962) <br></br>
                      - San Francisco Warriors (1962–1971) <br></br>
                      - Golden State Warriors (1971–today)<br></br>

                  11. <strong>Houston Rockets</strong>: <br></br>
                      - San Diego Rockets (1967–1971) <br></br>
                      - Houston Rockets (1971–today) <br></br>

                  12. <strong>Indiana Pacers</strong>:<br></br>
                      - Indiana Pacers (1967–today)<br></br>

                  13. <strong>LA Clippers</strong>: <br></br>
                      - Buffalo Braves (1970–1978)<br></br>
                      - San Diego Clippers (1978–1984)<br></br>
                      - LA Clippers (1984–today)<br></br>

                  14. <strong>Los Angeles Lakers</strong>:<br></br>
                      - Minneapolis Lakers (1947–1960)<br></br>
                      - Los Angeles Lakers (1960–today)<br></br>

                  15. <strong>Memphis Grizzlies</strong>:<br></br>
                      - Vancouver Grizzlies (1995–2001)<br></br>
                      - Memphis Grizzlies (2001–today)
                      <br></br>
                      </p>
                      <p className="squadreStoriche1">
                  16. <strong>Miami Heat</strong>:<br></br>
                      - Miami Heat (1988–today)<br></br>

                  17. <strong>Milwaukee Bucks</strong>:<br></br>
                      - Milwaukee Bucks (1968–today)<br></br>

                  18. <strong>Minnesota Timberwolves</strong>:<br></br>
                      - Minnesota Timberwolves (1989–today)<br></br>

                  19. <strong>New Orleans Pelicans</strong>:<br></br>
                      - New Orleans Hornets (2002–2013)<br></br>
                      - New Orleans Pelicans (2013–today)<br></br>

                  20. <strong>New York Knicks</strong>: <br></br>
                      - New York Knicks (1946–today)<br></br>

                  21. <strong>Oklahoma City Thunder</strong>:<br></br>
                      - Seattle SuperSonics (1967–2008)<br></br>
                      - Oklahoma City Thunder (2008–today)<br></br>

                  22. <strong>Orlando Magic</strong>:<br></br>
                      - Orlando Magic (1989–today)<br></br>

                  23. <strong>Philadelphia 76ers</strong>:<br></br>
                      - Syracuse Nationals (1946–1963)<br></br>
                      - Philadelphia 76ers (1963–today)<br></br>

                  24. <strong>Phoenix Suns</strong>:<br></br>
                      - Phoenix Suns (1968–today)<br></br>

                  25. <strong>Portland Trail Blazers</strong>:<br></br>
                      - Portland Trail Blazers (1970–today)<br></br>

                  26. <strong>Sacramento Kings</strong>:<br></br>
                      - Rochester Royals (1945–1957)<br></br>
                      - Cincinnati Royals (1957–1972)<br></br>
                      - Kansas City-Omaha Kings (1972–1975)<br></br>
                      - Kansas City Kings (1975–1985)<br></br>
                      - Sacramento Kings (1985–today)<br></br>

                  27. <strong>San Antonio Spurs</strong>:<br></br>
                      - Dallas Chaparrals (1967–1973)<br></br>
                      - San Antonio Spurs (1973–today)<br></br>

                  28. <strong>Toronto Raptors</strong>:<br></br>
                      - Toronto Raptors (1995–today)<br></br>

                  29. <strong>Utah Jazz</strong>:<br></br>
                      - New Orleans Jazz (1974–1979)<br></br>
                      - Utah Jazz (1979–today)<br></br>

                  30. <strong>Washington Wizards</strong>:<br></br>
                      - Chicago Packers (1961–1962)<br></br>
                      - Chicago Zephyrs (1962–1963)<br></br>
                      - Baltimore Bullets (1963–1973)<br></br>
                      - Capital/Washington Bullets (1973–1997)<br></br>
                      - Washington Wizards (1979–today)</p>
                    </div>
            </div>
          </div>
        )}
        {infoPrev && (
          <div className='popup-overlay' onClick={changeInfoPrev}>
            <div className='popup'>
              <p className="spiegazione">Click anywhere to continue</p>
            <div className="squadreStoriche">
                <p className="squadreStoriche1">1. <strong>Atlanta Hawks</strong>: <br></br>
                    - St. Louis Hawks (1955–1968) <br></br>
                    - Atlanta Hawks (1968-today) <br></br>

                  2. <strong>Boston Celtics</strong>: <br></br>
                    - Boston Celtics (1946–today) <br></br>

                  3. <strong>Brooklyn Nets</strong>: <br></br>
                    - New Jersey Americans (1967–1968) <br></br>
                    - New York Nets (1968–1977) <br></br>
                    - New Jersey Nets (1977–2012) <br></br>
                    - Brooklyn Nets (2012-today) <br></br>

                  4. <strong>Charlotte Hornets</strong>:<br></br>
                    - Charlotte Hornets (1988–2002) <br></br>
                    - Charlotte Bobcats (2004–2014)<br></br>
                    - Charlotte Hornets (2014–today)<br></br>

                  5. <strong>Chicago Bulls</strong>:<br></br>
                    - Chicago Bulls (1966–today)<br></br>

                  6. <strong>Cleveland Cavaliers</strong>:<br></br>
                    - Cleveland Cavaliers (1970–today)<br></br>

                  7. <strong>Dallas Mavericks</strong>:<br></br>
                    - Dallas Mavericks (1980–today)<br></br>

                  8. <strong>Denver Nuggets</strong>:<br></br>
                    - Denver Nuggets (1967–today)<br></br>

                  9. <strong>Detroit Pistons</strong>:<br></br>
                    - Fort Wayne Pistons (1941–1957)<br></br>
                    - Detroit Pistons (1957–today)<br></br>

                  10. <strong>Golden State Warriors</strong>:<br></br>
                      - Philadelphia Warriors (1946–1962) <br></br>
                      - San Francisco Warriors (1962–1971) <br></br>
                      - Golden State Warriors (1971–today)<br></br>

                  11. <strong>Houston Rockets</strong>: <br></br>
                      - San Diego Rockets (1967–1971) <br></br>
                      - Houston Rockets (1971–today) <br></br>

                  12. <strong>Indiana Pacers</strong>:<br></br>
                      - Indiana Pacers (1967–today)<br></br>

                  13. <strong>LA Clippers</strong>: <br></br>
                      - Buffalo Braves (1970–1978)<br></br>
                      - San Diego Clippers (1978–1984)<br></br>
                      - LA Clippers (1984–today)<br></br>

                  14. <strong>Los Angeles Lakers</strong>:<br></br>
                      - Minneapolis Lakers (1947–1960)<br></br>
                      - Los Angeles Lakers (1960–today)<br></br>

                  15. <strong>Memphis Grizzlies</strong>:<br></br>
                      - Vancouver Grizzlies (1995–2001)<br></br>
                      - Memphis Grizzlies (2001–today)
                      <br></br>
                      </p>
                      <p className="squadreStoriche1">
                  16. <strong>Miami Heat</strong>:<br></br>
                      - Miami Heat (1988–today)<br></br>

                  17. <strong>Milwaukee Bucks</strong>:<br></br>
                      - Milwaukee Bucks (1968–today)<br></br>

                  18. <strong>Minnesota Timberwolves</strong>:<br></br>
                      - Minnesota Timberwolves (1989–today)<br></br>

                  19. <strong>New Orleans Pelicans</strong>:<br></br>
                      - New Orleans Hornets (2002–2013)<br></br>
                      - New Orleans Pelicans (2013–today)<br></br>

                  20. <strong>New York Knicks</strong>: <br></br>
                      - New York Knicks (1946–today)<br></br>

                  21. <strong>Oklahoma City Thunder</strong>:<br></br>
                      - Seattle SuperSonics (1967–2008)<br></br>
                      - Oklahoma City Thunder (2008–today)<br></br>

                  22. <strong>Orlando Magic</strong>:<br></br>
                      - Orlando Magic (1989–today)<br></br>

                  23. <strong>Philadelphia 76ers</strong>:<br></br>
                      - Syracuse Nationals (1946–1963)<br></br>
                      - Philadelphia 76ers (1963–today)<br></br>

                  24. <strong>Phoenix Suns</strong>:<br></br>
                      - Phoenix Suns (1968–today)<br></br>

                  25. <strong>Portland Trail Blazers</strong>:<br></br>
                      - Portland Trail Blazers (1970–today)<br></br>

                  26. <strong>Sacramento Kings</strong>:<br></br>
                      - Rochester Royals (1945–1957)<br></br>
                      - Cincinnati Royals (1957–1972)<br></br>
                      - Kansas City-Omaha Kings (1972–1975)<br></br>
                      - Kansas City Kings (1975–1985)<br></br>
                      - Sacramento Kings (1985–today)<br></br>

                  27. <strong>San Antonio Spurs</strong>:<br></br>
                      - Dallas Chaparrals (1967–1973)<br></br>
                      - San Antonio Spurs (1973–today)<br></br>

                  28. <strong>Toronto Raptors</strong>:<br></br>
                      - Toronto Raptors (1995–today)<br></br>

                  29. <strong>Utah Jazz</strong>:<br></br>
                      - New Orleans Jazz (1974–1979)<br></br>
                      - Utah Jazz (1979–today)<br></br>

                  30. <strong>Washington Wizards</strong>:<br></br>
                      - Chicago Packers (1961–1962)<br></br>
                      - Chicago Zephyrs (1962–1963)<br></br>
                      - Baltimore Bullets (1963–1973)<br></br>
                      - Capital/Washington Bullets (1973–1997)<br></br>
                      - Washington Wizards (1979–today)</p>
                    </div>
            </div>
          </div>
        )}
        <div className="custom-alert-container">
          <CustomAlert message={alertMessage} visible={alertVisible} />
        </div>
        {/*<h2>Easy Players:</h2>
      <ul>
        {easyFilter.map((player, index) => (
          <li key={index}>
            {player.firstName} {player.lastName}
          </li>
        ))}
      </ul> */}
      {randomPlayer && (
        <div className="playerArea">
            <div className="playerImage" style={{backgroundImage: `url(${randomArrayPhoto[position]})`}}></div>
          <div className="coloreDati">
          <div className="playerName">
            <h1>
              {randomArrayPlayers[position].firstName} {randomArrayPlayers[position].lastName}
              {/*<br></br>
              level: {randomPlayer.level}*/}
            </h1>
          </div>
          <div className="numberOfTeams">
            <p>Played in <br></br> {randomArrayPlayers[position].teams.length} teams</p>
          </div>
          </div>
          {/*<p>{randomPlayer.teams}</p>*/}
        </div>
      )}
        <div className="hearts1">
          {Array.from({ length: 3 }, (_, index) => (
            <span
              key={index}
              className={`heart ${index < remainingHearts ? 'filled' : 'empty'}`}
            >
              <SportsBasketballTwoToneIcon style={{color: "orange"}} />
            </span>
          ))}
        </div>
        <div className="streak">
          <h2>SCORE: {completedPlayers}/10</h2>
        </div>
        <div className="countdown">
          <p className="numbersOfCountdown">{countdown > 0 ? `${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}` : "Time's up!"}</p>
          <button className="pausa" onClick={toggleTimer}>{timerPaused ? <PlayArrowRoundedIcon /> : <PauseRoundedIcon /> }</button>
          </div>

        <div>
          {!gameOver ? (
            <div>
              <div className="scacchiera">
                  <button style={{backgroundColor: `${color1}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick1}>1</button>
                  <button style={{backgroundColor: `${color2}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick2}>2</button>
                  <button style={{backgroundColor: `${color3}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick3}>3</button>
                  <button style={{backgroundColor: `${color4}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick4}>4</button>
                  <button style={{backgroundColor: `${color5}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick5}>5</button>
                  <button style={{backgroundColor: `${color6}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick6}>6</button>
                  <button style={{backgroundColor: `${color7}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick7}>7</button>
                  <button style={{backgroundColor: `${color8}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick8}>8</button>
                  <button style={{backgroundColor: `${color9}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick9}>9</button>
                  <button style={{backgroundColor: `${color10}`}} disabled={timerPaused} className="buttonOfPlayers" onClick={handleNextPlayerClick10}>10</button>
              </div>
              <div className="guessing1">
              <div className="inputArea">
              <div disabled={timerPaused} className="teamsInfo" onClick={changeInfo}>
                <LiveHelpRoundedIcon style={{color: "#a20095"}} />
          </div>
          <input
            className="inputTextArea"
            type="text"
            value={inputValue}
            disabled={timerPaused}
            placeholder="guess the teams..."
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleButtonClick();
              }
            }}
            ref={inputRef} // Aggiungi il riferimento all'elemento di input
          />

                {showDropdown && inputValue !== "" && squadreSuggerite.length > 0 && (
                  <ul>
                    {squadreSuggerite.map((squadra, index) => (
                      <li
                        className={`tendina ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                        key={index}
                        onClick={() => handleSquadraClick(squadra)}
                      >
                        {squadra}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
                <button className="insert" onClick={handleButtonClick} disabled={timerPaused}>
                  Insert
                </button>
              <div className="guessArea">
              {position === 0
                ? verificaMessages1.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 1
                ? verificaMessages2.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 2
                ? verificaMessages3.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 3
                ? verificaMessages4.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 4
                ? verificaMessages5.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 5
                ? verificaMessages6.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 6
                ? verificaMessages7.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 7
                ? verificaMessages8.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 8
                ? verificaMessages9.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : position === 9
                ? verificaMessages10.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))
                : null
              }
              </div>
              </div>
              <div className="prevNext">
                <button className="golden" disabled={timerPaused} onClick={handleNextPlayerClickPrev}><b>PREV</b><br></br><ArrowForwardIcon style={{transform: 'rotateY(180deg)'}} /></button>
                <button className="invisible"></button>
                <button className="invisible"></button>
                <button className="golden" disabled={timerPaused} onClick={handleNextPlayerClickGolden}><b>NEXT</b><br></br><ArrowForwardIcon /></button>
              </div>
            </div>
          ) : (
            <div>
              <p>Game Over! You did too many mistakes.</p>
              <p>Your final score is {completedPlayers}</p>
              <button onClick={refreshPage}>New game</button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Medium;
