import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../styles/PrevNickname.css'

function PrevNickname() {
    const [howToPlay, setHowtoplay] = useState(true)
  return (
    <div>
        <Navbar></Navbar>
    <div className='prevnickname'>
        {howToPlay && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div><h1 className='titoloHow'>HOW TO PLAY</h1><br></br><Link to="/nickname">
                    <button className='bottoneHow'>Let's play</button>
                </Link><br></br><p className='spiegazione'>The easiest one.<br></br>
You get a player’s nickname and you have to get whose named after that.<br></br>
As the other modes, the furthest you get, the hardest it gets</p>
                
                </div>
                }
            </div>
          </div>
        )}
    </div>
        <Footer></Footer>
    </div>
  )
}

export default PrevNickname