import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../styles/PrevNickname.css'

function PrevCareer() {
    const [howToPlay, setHowtoplay] = useState(true)
  return (
    <div>
        <Navbar></Navbar>
    <div className='prevnickname'>
        {howToPlay && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div><h1 className='titoloHow'>HOW TO PLAY</h1><br></br><Link to="/career">
                    <button className='bottoneHow'>Let's play</button>
                </Link><br></br><p className='spiegazione'>This mode is basically current's backwords.
Indeed you have to get the player by knowing the teams he has played for.
Players will be more difficult as the score increases.<br></br>
DISCLAIMERS:<br></br>
1) Who never even switched jersey once is not included<br></br>2) To be considered on a specific team, the player must have played at least one regular season match for that team</p>
                
                </div>
                }
            </div>
          </div>
        )}
    </div>
        <Footer></Footer>
    </div>
  )
}

export default PrevCareer