import React, { useEffect } from 'react'
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { players } from "../players";
import { useState } from 'react';
import '../styles/Career.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SportsBasketballTwoToneIcon from '@mui/icons-material/SportsBasketballTwoTone';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import giphy from '../assets/giphy (1).gif'
import livello1 from '../assets/livello1.jpg'
import livello2 from '../assets/livello2.jpg'
import livello3 from '../assets/livello3.jpg'
import livello4 from '../assets/livello4.jpg'
import livello5 from '../assets/livello5.jpg'
import livello6 from '../assets/livello6.jpg'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link } from 'react-router-dom';


/**
 * @typedef {Object} Player
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} img
 * @property {string[]} teams
 * @property {string} level
 * @property {number} firstNBAyear
 */


function Career() {
    const filtro = players.filter((player) => player.level.length > 0);
    const easyFilter = players.filter((player) => player.level === "easy" && player.teams.length > 1);
    const easymediumFilter = players.filter((player) => (player.level === "easy" || player.level === "medium") && player.teams.length > 1)
    const mediumFilter = players.filter((player) => player.level === "medium" && player.teams.length > 1);
    const mediumhardFilter = players.filter((player) => (player.level === "medium" || player.level === "hard") && player.teams.length > 1)
    const hardFilter = players.filter((player) => player.level === "hard" && player.teams.length > 1);
    const extrahardFilter = players.filter((player) => player.level === "extrahard" && player.teams.length > 1);
    const x = Math.floor(Math.random() * easyFilter.length);
    const [randomPlayer, setRandomPlayer] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [whatSkip, setWhatSkip] = useState(false)


    useEffect(() => {
        const randomPlayer = easymediumFilter[x];
        setRandomPlayer(randomPlayer);
    }, [])

    const [wrongGuesses, setWrongGuesses] = useState(0);
    const [gameOver, setGameOver] = useState(false)
    const [lives, setLives] = useState(3);


    const squadreDisponibili = [
        {nome: "Atlanta Hawks", stemma: "https://besthqwallpapers.com/Uploads/13-2-2021/154391/thumb2-atlanta-hawks-american-basketball-team-red-stone-background-atlanta-hawks-logo-grunge-art.jpg"},
        {nome: "Boston Celtics", stemma: "https://c2.staticflickr.com/8/7303/9165283038_c624a50d24_b.jpg"},
        {nome: "Brooklyn Nets", stemma: "https://c1.staticflickr.com/8/7404/9163061219_0bcc364023_n.jpg"},
        {nome: "Charlotte Hornets", stemma: "https://c2.staticflickr.com/6/5522/11543095774_c1cd22a887_b.jpg"},
        {nome: "Chicago Bulls", stemma: "https://th.bing.com/th/id/R.215d1ed77b91311663d0b30eafb55683?rik=YBdvj3CY9VAgww&pid=ImgRaw&r=0"},
        {nome: "Cleveland Cavaliers", stemma: "https://c2.staticflickr.com/8/7318/9165295218_0b1cf1c66c_b.jpg"},
        {nome: "Dallas Mavericks", stemma: "https://live.staticflickr.com/7316/9165297070_d26f85dd26.jpg"},
        {nome: "Denver Nuggets", stemma: "https://th.bing.com/th/id/OIP.kHDTYwEyJsUDae5ydxnG4AAAAA?pid=ImgDet&rs=1"},
        {nome: "Detroit Pistons", stemma: "https://th.bing.com/th/id/OIP.8xAvGpd-raTg5uP3ZKqfhwHaHa?pid=ImgDet&rs=1"},
        {nome: "Golden State Warriors", stemma: "https://upload.wikimedia.org/wikipedia/en/thumb/0/01/Golden_State_Warriors_logo.svg/1200px-Golden_State_Warriors_logo.svg.png"},
        {nome: "Houston Rockets", stemma: "https://c2.staticflickr.com/6/5323/9163085107_53853f4cf8_b.jpg"},
        {nome: "Indiana Pacers", stemma: "https://upload.wikimedia.org/wikipedia/sco/thumb/1/1b/Indiana_Pacers.svg/120px-Indiana_Pacers.svg.png"},
        {nome: "Los Angeles Clippers", stemma: "https://th.bing.com/th/id/R.642fc3516fccad6ce4ec04f09d1b567d?rik=Iq3BPWfHrEvizg&riu=http%3a%2f%2ffarm4.staticflickr.com%2f3706%2f9163093007_faab93d530_z.jpg&ehk=dT9U8JR812g1t7wtPnJfeGc34nYkPxpc4hA7Jer0lBw%3d&risl=&pid=ImgRaw&r=0"},
        {nome: "Los Angeles Lakers", stemma: "https://th.bing.com/th/id/R.2d0c77009a59f7f467a3156e9c007f78?rik=xglw%2f0uyEaguxg&riu=http%3a%2f%2ffarm8.staticflickr.com%2f7424%2f9163097199_a6113527eb_z.jpg&ehk=L60CIgkjLBYwJl2Pq3sR86RU1pnvpqUhBt6unsg5a%2bw%3d&risl=&pid=ImgRaw&r=0"},
        {nome: "Memphis Grizzlies", stemma: "https://th.bing.com/th/id/OIP.lJO3gqX06MfW00MrM09KgQHaG6?pid=ImgDet&rs=1"},
        {nome: "Miami Heat", stemma: "https://c1.staticflickr.com/3/2839/9165325852_2da3dbda0e_b.jpg"},
        {nome: "Milwaukee Bucks", stemma: "https://th.bing.com/th/id/R.ed0b232333f83e3fab8d1a093183cbbc?rik=G6FnTCtVsvL17Q&pid=ImgRaw&r=0"},
        {nome: "Minnesota Timberwolves", stemma: "https://upload.wikimedia.org/wikipedia/en/thumb/c/c2/Minnesota_Timberwolves_logo.svg/1200px-Minnesota_Timberwolves_logo.svg.png"},
        {nome: "New Orleans Pelicans", stemma: "https://c2.staticflickr.com/8/7412/9165344128_73c5fbd872_b.jpg"},
        {nome: "New York Knicks", stemma: "https://c1.staticflickr.com/3/2856/9163126439_ba341593de_b.jpg"},
        {nome: "Oklahoma City Thunder", stemma: "https://c2.staticflickr.com/8/7443/9165352690_fe2326dd13_b.jpg"},
        {nome: "Orlando Magic", stemma: "https://c2.staticflickr.com/6/5520/9163134135_742e34fa29_b.jpg"},
        {nome: "Philadelphia 76ers", stemma: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Philadelphia76ers2.png/95px-Philadelphia76ers2.png"},
        {nome: "Phoenix Suns", stemma: "https://c1.staticflickr.com/3/2839/9163137719_2a5af4e261_b.jpg"},
        {nome: "Portland Trail Blazers", stemma: "https://upload.wikimedia.org/wikipedia/en/thumb/2/21/Portland_Trail_Blazers_logo.svg/1200px-Portland_Trail_Blazers_logo.svg.png"},
        {nome: "Sacramento Kings", stemma: "https://th.bing.com/th/id/R.95d62e25deabbefbff22574ce1d2c0f4?rik=iEtbQUihU0p0Nw&pid=ImgRaw&r=0"},
        {nome: "San Antonio Spurs", stemma: "https://c2.staticflickr.com/4/3736/9163151905_f3219cffab_z.jpg"},
        {nome: "Toronto Raptors", stemma: "https://upload.wikimedia.org/wikipedia/en/thumb/3/36/Toronto_Raptors_logo.svg/1200px-Toronto_Raptors_logo.svg.png"},
        {nome: "Utah Jazz", stemma: "https://c1.staticflickr.com/5/4047/4329565096_f84bfa9c19_z.jpg?zz=1"},
        {nome: "Washington Wizards", stemma: "https://th.bing.com/th/id/OIP.W9EJOGU9t8yOr3fNzdi0gAAAAA?pid=ImgDet&rs=1"}
      ];

    const [playerTeams, setPlayerTeams] = useState([]);

    const playerTeamsOrder = randomPlayer ? [...randomPlayer.teams, "end"] : [];


    const [totalStreak, setTotalStreak] = useState(0);

    useEffect(() => {
        if (randomPlayer) {
          const teamsPlayed = randomPlayer.teams;
          const teamsData = squadreDisponibili.filter(team => teamsPlayed.includes(team.nome));
          setPlayerTeams(teamsData);
        }
      }, [randomPlayer]);      

    const [searchQuery, setSearchQuery] = useState('');
    const [suggestedPlayers, setSuggestedPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    useEffect(() => {
        const filteredPlayers = players.filter(player =>
            player.firstName.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
            player.lastName.toLowerCase().startsWith(searchQuery.toLowerCase())
        );
        setSuggestedPlayers(filteredPlayers);
    }, [searchQuery]);

    const handleInputChange = (event) => {
        const input = event.target.value.toLowerCase();
        setSearchQuery(input);
        setSelectedPlayer(null);
    };
    

    const handlePlayerSelect = (player) => {
        setSearchQuery(`${player.firstName} ${player.lastName}`);
        setSelectedPlayer(player);
    };

    const [streak, setStreak] = useState(0)

    const setWhat = () => {
        setWhatSkip(!whatSkip)
    }

    

    useEffect(() => {
        const filteredPlayers = players.filter(player => {
            const fullName = `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`;
            return fullName.includes(searchQuery);
        });

        if (searchQuery.trim() === '') {
            setSuggestedPlayers([]);
        } else {
            setSuggestedPlayers(filteredPlayers);
        }

    }, [searchQuery]);

    const [trulySkip, setTrulySkip] = useState(false)
    

    const handleInsert = () => {
        if (selectedPlayer) {
            if (selectedPlayer === randomPlayer) {
            setStreak(streak + 1);
            setPlayerTeams([]); // Reset delle squadre del giocatore precedente
            const updatedPlayerTeams = squadreDisponibili.filter(team =>
            selectedPlayer.teams.includes(team.nome)
            );
            setPlayerTeams(updatedPlayerTeams);

            setSearchQuery(''); // Reset dell'input di ricerca
            setSelectedPlayer(null); // Reset del giocatore selezionato

            let newIndex;
            let newRandomPlayer;

            // Genera un nuovo giocatore casuale
            do{
            if (streak >= 0 && streak <= 4) {
                newIndex = Math.floor(Math.random() * easyFilter.length);
                newRandomPlayer = easyFilter[newIndex]
              } else if (streak >= 5 && streak <= 6) {
                newIndex = Math.floor(Math.random() * easymediumFilter.length);
                newRandomPlayer = easymediumFilter[newIndex]
              } else if (streak >= 7 && streak <= 9) {
                newIndex = Math.floor(Math.random() * mediumFilter.length);
                newRandomPlayer = mediumFilter[newIndex]
              } else if (streak >= 10 && streak <= 13) {
                newIndex = Math.floor(Math.random() * mediumhardFilter.length);
                newRandomPlayer = mediumhardFilter[newIndex]
              } else if (streak >= 14 && streak <= 16) {
                newIndex = Math.floor(Math.random() * hardFilter.length);
                newRandomPlayer = hardFilter[newIndex]
              } else if (streak >= 17 && streak <= 20) {
                newIndex = Math.floor(Math.random() * extrahardFilter.length);
                newRandomPlayer = extrahardFilter[newIndex]
              } else {
                newIndex = Math.floor(Math.random() * filtro.length);
                newRandomPlayer = filtro[newIndex]
              }
            }while(selectedPlayers.includes(newRandomPlayer))

            setSelectedPlayers([...selectedPlayers,newRandomPlayer])

            setRandomPlayer(newRandomPlayer);
            setTrulySkip(false)
            } else {
            setWrongGuesses(wrongGuesses + 1);
            setLives(lives - 1); // Decrementa una vita
            setTrulySkip(true)
            setSearchQuery(''); // Reset dell'input di ricerca
            setSelectedPlayer(null);


            if (lives === 0) {
                setGameOver(true);
            }
            }

        }
        };


    useEffect(() => {
        if (wrongGuesses == 3) {
            setGameOver(true);
        } 
    }, [wrongGuesses]);

    const [howtoplay, setHowtoplay] = useState(true)

    const htpChange = () => {
        setHowtoplay(false)
    }

    const skip = () => {
        const newRandomPlayer = easyFilter[Math.floor(Math.random() * easyFilter.length)];
        setRandomPlayer(newRandomPlayer);
        setTrulySkip(false)
    }

    const [finalLevel, setFinalLevel] = useState("")
    const [finalLevelURL, setFinalLevelURL] = useState()

  useEffect(() => {
    if (streak<4){
      setFinalLevel("Oops you just missed a layup")
      setFinalLevelURL(livello1)
    } else if (streak>=4 && streak<7){
      setFinalLevel("Naah you can't brick an open three like this")
      setFinalLevelURL(livello2)
    }else if (streak>=7 && streak<13){
      setFinalLevel("You had a solid game, keep it up")
      setFinalLevelURL(livello3)
    }else if (streak>=13 && streak<18){
      setFinalLevel("You are splashing threes like Stephen Curry man")
      setFinalLevelURL(livello4)
    }else if (streak>=18 && streak<23){
      setFinalLevel("Congratulations, you are the LeBron James of our site")
      setFinalLevelURL(livello5)
    }else{
      setFinalLevel("You are just the G.O.A.T")
      setFinalLevelURL(livello6)
    }
  },[streak])


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        navigatePlayer(-1);
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        navigatePlayer(1);
      } else if (event.key === "Enter") {
        event.preventDefault();
        handleInsert();
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [suggestedPlayers, selectedPlayer]);
  
  const navigatePlayer = (direction) => {
    const currentIndex = suggestedPlayers.findIndex(
      (player) => player === selectedPlayer
    );
    let newIndex = currentIndex + direction;
    if (newIndex < 0) {
      newIndex = suggestedPlayers.length - 1;
    } else if (newIndex >= suggestedPlayers.length) {
      newIndex = 0;
    }
    setSelectedPlayer(suggestedPlayers[newIndex]);
  };

    function refreshPage() {
        window.location.reload(false);
    }
    
    

    return (
        <div>
            <Navbar></Navbar>
            <div className="career">
            {gameOver && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div className="fine"><div className="cry"><img className="cryImg" src={finalLevelURL} /></div>
                <div className="testoFine">
                <p className="spiegazione">{finalLevel}. Your final score is {streak}.<br></br>  
                <strong>The player was {randomPlayer.firstName} {randomPlayer.lastName}</strong>
                </p>
                <br></br>
                <button className="bottoneHow1" onClick={refreshPage}>New Game</button><button style={{color: "transparent", backgroundColor: "transparent", cursor: "default"}}>OMG an easter egg</button>
                <Link to="/"><button className='bottoneHow2'><HomeRoundedIcon /><br></br>Home</button></Link>
                </div></div>}
            </div>
          </div>
        )}
        {whatSkip && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div>
                <p className='spiegazione'>When you fail to guess a player you have two choices:<br></br>1) Skip him and get another one.<br></br>2) Try to guess the one you just missed</p><br></br>
                <button className='bottoneHow' onClick={setWhat}>DONE</button></div>}
            </div>
          </div>
        )}
                <div className='gridGame'>
                <div className='streak1'>
                    <h2><b>SCORE: {streak}</b></h2>
                </div>
                <div className="lives">
                    {Array.from({ length: lives }, (_, index) => (
                    <SportsBasketballTwoToneIcon style={{color: "orange"}} key={index} role="img" aria-label="Heart Emoji">
                    </SportsBasketballTwoToneIcon>
                    ))}
                </div>
                </div>
                <div>
                    {!gameOver ? (
                        <div className='inputAre'>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleInputChange}
                            placeholder="guess the player..."
                        />
                        {suggestedPlayers.length > 0 && (
                            <ul className="suggested-players">
                                {suggestedPlayers.map((player, index) => (
                                    <li className={`player ${player === selectedPlayer ? 'selected' : ''}`} key={index} onClick={() => handlePlayerSelect(player)}>
                                        {`${player.firstName} ${player.lastName}`}
                                    </li>
                                ))}
                            </ul>
                        )}
                        <button onClick={handleInsert}>Insert</button>
                        {trulySkip && <div className='skiss'>
                            <div className='whatsinside' onClick={setWhat}>
                                <LiveHelpRoundedIcon style={{color: "#a20095"}}></LiveHelpRoundedIcon>
                            </div>
                            <div>
                                <button style={{border: `1px solid orange`}} onClick={skip}>Skip</button>
                            </div>
                        </div>}
                    </div>
                    ) : (
                        <div>
                            <p>Game over</p>
                            <button onClick={refreshPage}>NEW GAME</button>
                            <p>The player was {randomPlayer.firstName} {randomPlayer.lastName}</p>
                        </div>
                    )}
                </div>
                <div className='gridAreaSotto'>
                    <div className="team-images">
                        {playerTeamsOrder.map((teamName, index) => {
                            if (teamName === "end") {
                                // Questa è l'indicatore di fine, non renderizzare la freccia
                                return null;
                            }

                            const team = squadreDisponibili.find(team => team.nome === teamName);
                            if (!team) return null; // Ignora le squadre non trovate

                            return (
                                <div key={index} className="team-image-container">
                                    <div>
                                        <div className='squadre' style={{backgroundImage: `url(${team.stemma})`}}></div>
                                        <p className='nomeSquadra'><b>{team.nome}</b></p>
                                    </div>
                                    <div className='freccia'>
                                        {/* Renderizza la freccia solo se NON è l'ultima squadra */}
                                        {index !== playerTeamsOrder.length - 2 && <ArrowForwardIcon />}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='firstYear'>
                        {randomPlayer && (
                            <p className='primoAnno'><b>Plays in NBA since {randomPlayer.firstNBAyear - 1}/{randomPlayer.firstNBAyear} season</b></p>
                        )}
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Career