import React, { useState } from 'react'
import { Instagram } from '@mui/icons-material'
import { Facebook } from '@mui/icons-material'
import { LinkedIn } from '@mui/icons-material'
import { Twitter } from '@mui/icons-material'
import '../styles/Footer.css'
import { Link } from 'react-router-dom'
import TikTok from '../assets/tiktok-svgrepo-com.png'
import {YouTube} from '@mui/icons-material'

function Footer() {
  const [inst, setInsta] = useState(false)
  const [twit, setTwit] = useState(false)
  return (
    <div className='footer'>
        <p style={{color: "#a20095", fontFamily: "monospace"}}>Inspired by: Poeltl, Larry Birdle, KOT4Q and Fabio Caressa. Data sources: Basketball reference. It is highly recommended to play Curryosity on PC for a better experience.</p>
        <div className='socials'>
          <a href="https://instagram.com/basketballcurryosity/" target="_blank" style={{ textDecoration: 'none' }}><Instagram /></a>
          <a href="https://youtube.com/@basketballcurryosity?si=7SNO639AwLhtYduC" target="_blank" style={{ textDecoration: 'none' }}><YouTube /></a>
          <a href="https://tiktok.com/@nbasketballcurryosity/" target='_blank' style={{ textDecoration: 'none' }}><img src={TikTok}></img></a>
        </div>
        <p style={{color: "#a20095", fontSize: "15px"}}> Founded by: Lorenzo Artioli and Fabio Marchi </p>
    </div>
  )
}

export default Footer