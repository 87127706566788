import React from 'react'
import { players } from '../players'
import '../styles/Extrahard.css'

function ExtraHard() {
    const easyFilter = players.filter(player => player.level === "extra hard")
    const x = Math.floor(Math.random() * easyFilter.length);
    const randomPlayer = easyFilter[x];
    return (
      <div className='extrahard'>
                <h2>Extra Hard Players:</h2>
        <ul>
          {easyFilter.map((player, index) => (
            <li key={index}>
              {player.firstName} {player.lastName}
            </li>
          ))}
        </ul>
        <h3>Random Player Details:</h3>
      {randomPlayer && (
        <div>
          <p>Name: {randomPlayer.firstName} {randomPlayer.lastName}</p>
          <p>Teams: {randomPlayer.teams.join(", ")}</p>
          <img src={randomPlayer.img}></img>
        </div>
      )}
      </div>
    )
}

export default ExtraHard