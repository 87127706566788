import React from 'react'
import { topPlayers } from '../topPlayers';
import '../styles/Hard.css'

function Hard() {
    const easyFilter = topPlayers.filter(player => player.level === "hard")
    const x = Math.floor(Math.random() * easyFilter.length);
    const randomPlayer = easyFilter[x];
    return (
      <div className='hard'>
                <h2>Hard Players:</h2>
        <ul>
          {easyFilter.map((player, index) => (
            <li key={index}>
              {player.firstName} {player.lastName}
            </li>
          ))}
        </ul>
        <h3>Random Player Details:</h3>
      {randomPlayer && (
        <div>
          <p>Name: {randomPlayer.firstName} {randomPlayer.lastName}</p>
          <p>Teams: {randomPlayer.teams.join(", ")}</p>
          <img src={randomPlayer.img}></img>
        </div>
      )}
      </div>
    )
}

export default Hard