import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../styles/PrevNickname.css'

function PrevEasy() {
    const [howToPlay, setHowtoplay] = useState(true)
  return (
    <div>
        <Navbar></Navbar>
    <div className='prevnickname'>
        {howToPlay && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div><h1 className='titoloHow'>HOW TO PLAY</h1><br></br><Link to="/easy">
                    <button className='bottoneHow'>LET'S PLAY</button>
                </Link><br></br><p className='spiegazione'>The current mode works like this:<br></br>
As you get a player you have to guess all the teams he has played for.<br></br>
By doing that you increase your score and get another player. Do the same thing until you can.<br></br>
As the score gets higher the game will automatically get harder.
You have also two tools to help you.(golden skip and silver skip).</p>
                
                </div>
                }
            </div>
          </div>
        )}
    </div>
        <Footer></Footer>
    </div>
  )
}

export default PrevEasy