import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Home.css'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import James from '../assets/James.jpg'
import Larry from '../assets/Larry.jpg'
import KobeJordan from '../assets/kobejordan.jpg'
import blackWhite from '../assets/blackwhite.webp'
import game1 from '../assets/LeGuessTheTeam.jpg'
import game2 from '../assets/immaginetop75.jpg'
import game3 from '../assets/LeGuessThePlayer.jpg'
import game4 from '../assets/Nicknames.jpg'
import News from '../components/News'
import 'firebase/firestore'
import 'firebase/compat/firestore';
import firebase from '../firebase'; // Modifica il percorso del tuo file firebase.js

function Home() {
    const firestore = firebase.firestore();
    const [toggle, setToggle] = useState(false);
    const [display, setDisplay] = useState(true);

    const toggleOpen = () => {
        setToggle(v => !v);
        setDisplay(false);
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const dataRef = firestore.collection('players');
            const snapshot = await dataRef.get();
            const fetchedData = snapshot.docs.map(doc => doc.data());
            const sortedData = fetchedData.sort((a, b) => b.punteggio - a.punteggio); // Ordina per punteggio decrescente
            setData(sortedData);
            console.log(sortedData);
        };
        fetchData();
    }, [firestore]);

    return (
        <div>
            <Navbar />
            <News />
            <div className='home'>
                {display &&
                    <button className="bottone" onClick={toggleOpen}><b>PLAY</b></button>
                }
                {/*
                <div>
                {data.map((player, index) => (
                                <div key={index}>
                                    <p>{player.name} - {player.punteggio}</p>
                                </div>
                            ))}
                </div>    
                        */}
                {toggle && (
                    <div className='livello' id={toggle ? "open" : "close"}>
                        <div className='livelli-container'>
                            <div className='livelli' style={{ backgroundImage: `url(${game1})` }}>
                                <Link to="/preveasy">
                                    <button><b>LeGuess the Team</b></button>
                                </Link>
                            </div>
                            <br />
                            <div className='livelli' style={{ backgroundImage: `url(${game2})` }}>
                                <Link to="/prevmedium">
                                    <button><b>Top 75 players of all time</b></button>
                                </Link>
                            </div>
                            <br />
                            <div className='livelli' style={{ backgroundImage: `url(${game3})` }}>
                                <Link to="/prevcareer">
                                    <button><b>LeGuess the Player</b></button>
                                </Link>
                            </div>
                            <br />
                            <div className='livelli' style={{ backgroundImage: `url(${game4})` }}>
                                <Link to="/prevnickname">
                                    <button><b>Historical Nicknames</b></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    )
}

export default Home;