export const curiosita = [
    {
        frase: "Cam Thomas was actually born in Yokosuka, Japan because his mother was living there"
    },
    {
        frase: "Reggie Jackson was actually born in Pordenone, Italy because his father served at Aviano Air Base."
    },
    {
        frase: "In 20-21 and 21-22 the Bulls team included players named White, Brown and Green."
    },
    {
        frase: "In 22-23 season in Oklahoma City's roster there were three different Williams."
    },
    {
        frase: ". In 2010 NBA Finals, Kobe Bryant ignited a solo scoring spree with 23 consecutive points. Despite the Lakers' setback, Kobe's resilience led to a triumphant comeback, securing his 5th ring and Finals MVP. 🏀🔥 #MambaMentality"

    },
    {
        frase: " Tired of the 'Number 2' label, Kevin Durant enlisted Justin Zormelo, a 29-year-old analytics whiz with no basketball background, turning every possession into a strategic masterpiece. Elevating from second to first, Durant's unconventional move reshaped the game. 🏀💡"
    },
    {
        frase: "Chris Paul’s grandpa Nathaniel Joneswas brutally murdered at 61. To honor him  with an emotional tribute, he scored61 points—one for each year of his life. To cap it at 61, Paul intentionally misses the last free throw, tears flowing on the bench. A poignant moment etched in NBA history."
    },
    {
        frase: "Alonzo Mourning, Miami Heat legend and weightlifting powerhouse, faced a sudden health setback. Overcoming kidney disease with a cousin's gift of a transplant, he triumphantly returned, fueling the team to their first historic championship. "
    },
    {
        frase: " Zo Mourning, a warrior on and off the court, battled a career-ending knee injury. In a defiant exit, he dismissed healthcare pros, leaving the court on his own legs. "
    },
    {
        frase: "Dennis Rodman defines himself as the 5th person more identifiable on the world just after: God, Jesus, Muhammad Ali and Barak Obama."
    },
    {
        frase: "Dennis Rodman married himself dressed as a woman at Rockfeller Center, New York to advertise the release of his book."
    },
    {
        frase: "It is said that Dennis Rodman's grandpa had 99 children."
    },
    {
        frase: "Dennis Rodman's grandpa reportedly had a staggering 99 children, adding a unique twist to the colorful Rodman family tree"
    },
    {
        frase: " In 2005, Dennis Rodman entered the Wife Carrying World Championship in remote Finland. The prize: the champion's weight in beer. A quirky detour in the Rodman saga. "
    },
    {
        frase: "Dennis Rodman, sent to Rome by an Irish betting company, shared insights on the papal conclave. They bet on the next pope's race, banking on Rodman's unconventional expertise. "
    },
    {
        frase: "Dennis Rodman was once Kim's guest in Pyongyang because Kim was used to always wear Rodman's uniform in an swiss college. So Rodman, developed a friendship with the president and became the only way USA have to speak with him."
    },
    {
        frase: "Dennis Rodman, asked about fashion regrets, quipped, 'a condom.' Unapologetically bold on and off the court, his style echoes in NBA lore. "
    },
    {
        frase: "Hakeem Olajuwon, a dedicated Muslim athlete, honored Ramadan's fasting tradition during his NBA career. Remarkably, in February '95, he clinched Player of the Month, scoring 45 points against Michael Jordan while observing the fast. A testament to his unwavering commitment on and off the court."
    },
    {
        frase: "At 19, Hakeem Olajuwon in Nigeria received three college scholarship offers. Landing in chilly New York, he wisely chose to avoid the northern cold, leading him to play for the University of Houston. A pivotal decision shaping a legendary career."
    },
    {
        frase: " When Hakeem Olajuwon arrived in Houston, no one met him at the airport. Four years later, he emerged as the first overall pick in the 1984 NBA Draft, chosen before even Michael Jordan. From a lone arrival to a draft triumph, Olajuwon's journey is the stuff of legends"
    },
    {
        frase: "In the year Jordan retired, Hakeem Olajuwon dominated, clinching MVP, Finals MVP, and Defensive Player of the Year. A testament to Olajuwon's unparalleled skill, earning top honors in a Jordan-less era. "
    },
    {
        frase: "LeBron James credits Hakeem Olajuwon for shaping his post-game skills. The King acknowledges Olajuwon's influence, showcasing the enduring impact of basketball legends on the court."
    },
    {
        frase: ".To honor Ramadan, Olajuwon travels to the United States, challenging himself and showcasing the strength of his faith. A modern testament to his unwavering commitment."
    },
    {
        frase: "Hakeem Olajuwon is honored with a statue outside Houston's sports arena, defying Islamic artistic norms. A symbolic tribute to his towering legacy in the heart of the basketball world. "
    },
    {
        frase: "Chris Andersen faced a 2-year NBA suspension for substance use. A setback in Birdman's soaring career, highlighting the challenges athletes sometimes grapple with off the court."
    },
    {
        frase: "At 12, Chris Andersen inked his first tattoo, yin and yang symbols, on Route 66 in Albuquerque, New Mexico. A timeless journey marked by art on America's iconic highway."
    },
    {
        frase: "After college, Chris Andersen went undrafted because he forgot to declare himself eligible. A quirky twist in Birdman's journey to the NBA. "
    },
    {
        frase: "Chris Andersen made history as the first player to transition from the G League to the NBA, paving the way for future talents. A trailblazer in the league's development system."
    },
    {
        frase: "Recruited by Norm Stewart of the University of Missouri, Chris Andersen, when asked about his off-court hobbies, responded with a straightforward, party and chase, A candid glimpse into the Birdman's lifestyle"
    },
    {
        frase: "Before joining the Miami Heat, Chris Andersen, summoned by Pat Riley, embarked on an archery moose hunt. A unique prelude to accepting Riley's offer and becoming a pivotal part of the Heat."
    },
    {
        frase: "In the latest off-season, a unique roster shuffle occurred with Brown, Brown Jr., Jones, Jones Jr., Smith, and Smith Jr. all changing teams. A twist that left fans decoding the jersey numbers"
    },
    {
        frase: " At their first encounter with the Dream Team, Cuba's basketball team creatively hid cameras in their socks. Coordinated with the signal 'ahora, ahora' ('now, now'), a player in the low post captured moments, creating a quirky clash of tactics. "
    },
    {
        frase: "Aware of KGB surveillance outside the now-defunct USSR, Sabonis would rent large Cadillacs. Ingeniously, he'd slip into the trunk to thwart potential tailing, showcasing the hoops legend's off-court escapades."
    },
    {
        frase: "Sabonis's mother endured 9 years in Siberia for expressing dissenting views. A testament to the sacrifices made by the family behind the basketball legend."
    },
    {
        frase: "Jerry West, the inaugural Finals MVP winner from a losing team. LeBron James, arguably deserving in 2015, stirs debate. West's unique achievement stands, fueling the 'What if?' chatter"
    },
    {
        frase: "In 1966, Bill Russell broke barriers as the first African American head coach in U.S. professional sports history. A trailblazer on and off the court, leaving an indelible mark"
    },
    {
        frase: "For years, the New Jersey Nets were known as the team of the Meadowlands, referencing their lack of a specific hometown. Often associated with Exit 16W on the highway, as they played at the Meadowlands Arena. Legends suggest Jimmy Hoffa, a famous union leader, is buried there, adding a touch of mystery."
    },
    {
        frase: "Before hosting the Nets, Brooklyn lacked a pro team since 1957 when the Brooklyn Dodgers left. Allegedly, locals dodged the team, known for its move to LA. In '57, the Dodgers aimed to rebuild but, thwarted, headed to LA, birthing the famous Los Angeles Dodgers baseball team. "
    },
    {
        frase: "The Barclays Center in Brooklyn stands where, in '57, the Brooklyn Dodgers aimed to build their baseball stadium, but were denied. Today, it's a slam dunk for the Nets"
    },
    {
        frase: "In the 1950s, Jerry Buss struck oil gold beneath his home, amassing wealth that fueled savvy investments, ultimately leading to the purchase of the Lakers."
    },
    {
        frase: "Jerry Buss revolutionized NBA entertainment, reshaping the league's image. By introducing glamorous halftime shows, celebrities, and the iconic Laker Girls, he transformed basketball into a captivating spectacle"
    },
    {
        frase: "Days before turning 50, MJ was challenged by 20-year-old Michael Kidd-Gilchrist, who boldly claimed to have been dominated by the basketball legend. The timeless MJ magic still leaving young stars in awe. "
    },
    {
        frase: "Around 2010, Tim Grover, MJ's iconic trainer, reportedly had a file on his desk outlining a plan to bring MJ back to the NBA at 50. A glimpse into the enduring desire for greatness."
    },
    {
        frase: "At just 2 years old, Michael Jordan narrowly escaped a lightning strike, foreshadowing the unstoppable force he would become. A legend's journey begins in the face of destiny."
    },
    {
        frase: "At 17, a martial arts master's demo went awry, slicing a grapefruit into Michael Jordan's chest. A mere taste of the challenges he'd conquer on his journey to greatness. "
    },
    {
        frase: "At 7, Michael Jordan witnessed his best friend drown in the ocean, sparking a lifelong fear of water. A phobia so intense that MJ has never taken a bath"
    },
    {
        frase: "Mark Cuban, born to Russian-Jewish immigrants, embodies the American dream. His family, part of a wave of 10 million immigrants, changed their name from Chabenisky to Cuban upon arrival. From immigrant roots to NBA ownership, Cuban's journey is a testament to the pursuit of success. "
    },
    {
        frase: "Before rising as a business tycoon and one of the world's 500 wealthiest individuals, Mark Cuban started as a cha-cha-cha dance instructor. From dance floors to boardrooms, his journey is a rhythm of success"
    },
    {
        frase: "Mark Cuban's breakthrough idea was streaming Indiana University basketball games online as radio broadcasts. He founded broadcast.com, that Yahoo.com later acquired. A visionary move that changed the game on and off the cour"
    },
    {
        frase: "Ever wondered if you could shoot an email to Mark Cuban? Turns out, the billionaire entrepreneur is reachable via email. Who knows, your next big idea might just land in his inbox"
    },
    {
        frase: " Breaking barriers on and off the court, Jason Collins made history as the first NBA player to come out as gay during his career. Even his ex-girlfriend and twin brother were taken by surprise"
    },
    {
        frase: "Glory Road, a commemorative film about Texas Western University's 1966 NCAA victory, distorts history in its depiction of a non-existent comeback. In reality, Texas Western dominated the final, but for cinematic drama, it's portrayed as a come-from-behind triumph."
    },
    {
        frase: "Pat Riley, served as a consultant for the film Glory Road. Indeed he was on the court during the final game depicted in the movie. He stated that he wouldn't have become who he is today without experiencing that pivotal loss."
    },
    {
        frase: "After retiring, Oscar Robertson became the head of the players' union. Thanks to him, the concept of free agency was established, a practice still used today in the world of basketball. "
    },
    {
        frase: "Did you know that JaVale McGee is a basketball legacy? His mother, Pamela McGee, is a two-time U.S. champion and an Olympic gold medalist. Basketball greatness runs in the family"
    },
    {
        frase: "After playing basketball in Parma, Pamela McGee, JaVale McGee's mother, faced a crucial decision. Pregnant at the end of the season, she initially considered an abortion. However, a divine vision changed her mind, and now we celebrate the incredible talent of JaVale McGee"
    },
    {
        frase: "In the NBA, each team now has a 24-second shot clock, but originally, there were no time restrictions for each possession. In a historic Pistons-Lakers game, the final score was 19-18, as the Pistons, having taken the lead, opted to hold onto the ball"
    },
    {
        frase: "Three years after a memorable 19-18 Pistons-Lakers game, the Celtics took the spotlight with a staggering 106 fouls and 128 free throws in a single game. 🏀 Fouls and free throws reached unprecedented heights"
    },
    {
        frase: "After intense games capped at 20 points with numerous fouls, Syracuse Nationals owner Danny Biasone conceived the shot clock. Believing the ideal game involved 60 shots per team, he divided 48 minutes by 120 (total shots) leading to the iconic 24-second rule."
    },
    {
        frase: "In 1955, the first year of the 24-second shot clock era, the Syracuse Nationals triumphed, benefiting from their owner's instrumental role in changing the game's rules"
    },
    {
        frase: " In the Philippines, the shot clock was set to 25 seconds due to logistical challenges in synchronizing timers for 24 seconds in larger arenas. This persisted until the 1990s when they successfully made the switch."
    },
    {
        frase: "The Lakers are named after the land of lakes in Minnesota, where they originated. Ironically, Los Angeles, their current home, doesn't boast many lakes. "
    },
    {
        frase: "In 1992, months after revealing he was HIV positive, Magic Johnson not only made the All-Star Game starting lineup but also dominated, earning Finals MVP honors. This paved the way for his iconic role on the legendary Dream Team"
    },
    {
        frase: "Kobe Bryant was renowned for quizzing teammates on their roles and responsibilities on the court. His dedication to strategy and precision contributed to his legacy as one of the NBA's all-time greats."
    },
    {
        frase: "Vanessa Bryant, convinced she would marry Kobe Bryant, strategically appeared in a 50-second cameo in a rap song's TV spot featuring Kobe. Fate brought them together, creating a legendary love story"
    },
    {
        frase: "By Bobby Knight's choice, Indiana University players never had their last names on the back of their jerseys. He believed the name on the front was more important. "
    },
]