import React, { useEffect, useState } from 'react'
import '../styles/News.css'
import { curiosita } from '../Curiosita'

function News() {
    const filtro = curiosita.filter((curiosita) => curiosita.frase.length > 0);
    const [curiosityIndex, setCuriosityIndex] = useState(0);
    const [randomCuriosity, setRandomCuriosity] = useState(filtro[Math.floor(Math.random() * filtro.length)]);

    useEffect(() => {
        const interval = setInterval(() => {
            // Calcola l'indice successivo in modo ciclico
            const nextIndex = (curiosityIndex + 1) % filtro.length;
            setCuriosityIndex(nextIndex);
            setRandomCuriosity(filtro[nextIndex]);
        }, 500000); // Cambia la frase ogni 5 secondi (o l'intervallo desiderato)

        return () => {
            clearInterval(interval); // Pulisce l'interval quando il componente si smonta
        };
    }, [curiosityIndex, filtro]);

    return (
        <div className='container'>
            <div className='ticker'>
                <div className='title'><h5><strong>DID YOU KNOW THAT</strong></h5></div>
                <div className='news'>
                    <marquee>
                        {randomCuriosity && (
                            <p>
                                {randomCuriosity.frase}
                            </p>
                        )}
                    </marquee>
                </div>
            </div>
        </div>
    )
}

export default News
