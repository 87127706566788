import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { surname } from '../surnames'
import '../styles/Nickname.css'
import { useState, useEffect } from 'react'
import SportsBasketballTwoToneIcon from '@mui/icons-material/SportsBasketballTwoTone';
import livello1 from '../assets/livello1.jpg'
import livello2 from '../assets/livello2.jpg'
import livello3 from '../assets/livello3.jpg'
import livello4 from '../assets/livello4.jpg'
import livello5 from '../assets/livello5.jpg'
import livello6 from '../assets/livello6.jpg'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link } from 'react-router-dom'
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';


/**
 * @typedef {Object} Player
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} img
 * @property {string[]} soprannomi
 */

function Nickname() {
    const filtro = surname.filter((player) => player.level.length > 0);
    const easyFilter = surname.filter((player) => player.level === "easy");
    const easymediumFilter = surname.filter((player) => (player.level === "easy" || player.level === "medium"))
    const mediumFilter = surname.filter((player) => player.level === "medium");
    const mediumhardFilter = surname.filter((player) => (player.level === "medium" || player.level === "hard"))
    const hardFilter = surname.filter((player) => player.level === "hard");
    const extrahardFilter = surname.filter((player) => player.level === "extra-hard");
    const x = Math.floor(Math.random() * easyFilter.length);
    const [randomPlayer, setRandomPlayer] = useState(null);
    const [pos, setPos] = useState(0)
    const [streak, setStreak] = useState(0)
    const [wrong, setWrong] = useState(0)
    const [gameOver, setGameOver] = useState(false)
    const [lives, setLives] = useState(1);
    const [usedPlayers, setUsedPlayers] = useState([]);
    const [skip, setSkip] = useState(true)
    const [skipsNum, setSkipsNum] = useState(1)
    

    


    useEffect(() => {
        const randomPlayer = easyFilter[x];
        setRandomPlayer(randomPlayer);
        if (randomPlayer.soprannomi.length > 1){
            let c = Math.floor(Math.random() * randomPlayer.soprannomi.length)
            setPos(c)
        }
        setUsedPlayers([...usedPlayers, randomPlayer])
    }, [])

    const [searchQuery, setSearchQuery] = useState('');
    const [suggestedPlayers, setSuggestedPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    useEffect(() => {
        const filteredPlayers = surname.filter(player =>
            player.firstName.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
            player.lastName.toLowerCase().startsWith(searchQuery.toLowerCase())
        );
        setSuggestedPlayers(filteredPlayers);
    }, [searchQuery]);

    const handleInputChange = (event) => {
        const input = event.target.value.toLowerCase();
        setSearchQuery(input);
        setSelectedPlayer(null);
    };

    const handlePlayerSelect = (player) => {
        setSearchQuery(`${player.firstName} ${player.lastName}`);
        setSelectedPlayer(player);
    };

    const handleInsert = () => {
        if (selectedPlayer) {
            if (selectedPlayer === randomPlayer) {
            setStreak(streak + 1);

            setSearchQuery(''); // Reset dell'input di ricerca
            setSelectedPlayer(null); // Reset del giocatore selezionato

            let newIndex;
            let newRandomPlayer;


            do{if (streak >= 0 && streak <= 4) {
                newIndex = Math.floor(Math.random() * easyFilter.length);
                newRandomPlayer = easyFilter[newIndex]
              } else if (streak >= 5 && streak <= 6) {
                newIndex = Math.floor(Math.random() * easymediumFilter.length);
                newRandomPlayer = easymediumFilter[newIndex]
              } else if (streak >= 7 && streak <= 8) {
                newIndex = Math.floor(Math.random() * mediumFilter.length);
                newRandomPlayer = mediumFilter[newIndex]
              } else if (streak >= 9 && streak <= 11) {
                newIndex = Math.floor(Math.random() * mediumhardFilter.length);
                newRandomPlayer = mediumhardFilter[newIndex]
              } else if (streak >= 12 && streak <= 15) {
                newIndex = Math.floor(Math.random() * hardFilter.length);
                newRandomPlayer = hardFilter[newIndex]
              } else if (streak >= 16 && streak <= 20) {
                newIndex = Math.floor(Math.random() * extrahardFilter.length);
                newRandomPlayer = extrahardFilter[newIndex]
              } else {
                newIndex = Math.floor(Math.random() * filtro.length);
                newRandomPlayer = filtro[newIndex]
              }
            }while (usedPlayers.includes(newRandomPlayer))

            

            setRandomPlayer(newRandomPlayer);
            setUsedPlayers([...usedPlayers, randomPlayer])


            if (newRandomPlayer.soprannomi.length > 1){
                let c = Math.floor(Math.random() * randomPlayer.soprannomi.length)
                setPos(c)
            }else{
                setPos(0)
            }
            }else if (selectedPlayer != randomPlayer && wrong == 0){
              setGameOver(true)
            }else{
                let newIndex;
                let newRandomPlayer;

                do{
                if (streak >= 0 && streak <= 3) {
                    newIndex = Math.floor(Math.random() * easyFilter.length);
                    newRandomPlayer = easyFilter[newIndex]
                } else if (streak >= 4 && streak <= 5) {
                    newIndex = Math.floor(Math.random() * easymediumFilter.length);
                    newRandomPlayer = easymediumFilter[newIndex]
                } else if (streak >= 6 && streak <= 8) {
                    newIndex = Math.floor(Math.random() * mediumFilter.length);
                    newRandomPlayer = mediumFilter[newIndex]
                } else if (streak >= 9 && streak <= 11) {
                    newIndex = Math.floor(Math.random() * mediumhardFilter.length);
                    newRandomPlayer = mediumhardFilter[newIndex]
                } else if (streak >= 12 && streak <= 15) {
                    newIndex = Math.floor(Math.random() * hardFilter.length);
                    newRandomPlayer = hardFilter[newIndex]
                } else if (streak >= 16 && streak <= 19) {
                    newIndex = Math.floor(Math.random() * extrahardFilter.length);
                    newRandomPlayer = extrahardFilter[newIndex]
                } else {
                    newIndex = Math.floor(Math.random() * filtro.length);
                    newRandomPlayer = filtro[newIndex]
                }
            }while (usedPlayers.includes(newRandomPlayer))

                setRandomPlayer(newRandomPlayer);
                setUsedPlayers([...usedPlayers, randomPlayer])
                setWrong(wrong+1)
                setSearchQuery(''); // Reset dell'input di ricerca
                setSelectedPlayer(null);
                setLives(lives-1)
                if (newRandomPlayer.soprannomi.length > 1){
                    let c = Math.floor(Math.random() * randomPlayer.soprannomi.length)
                    setPos(c)
                }else{
                    setPos(0)
                }
            }

        }
        };

        useEffect(() => {
            const filteredPlayers = surname.filter(player => {
                const fullName = `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`;
                return fullName.includes(searchQuery);
            });
    
            if (searchQuery.trim() === '') {
                setSuggestedPlayers([]);
            } else {
                setSuggestedPlayers(filteredPlayers);
            }
    
        }, [searchQuery]);

        useEffect(() => {
            if (wrong==3){
                setGameOver(true)
            }
        })

        useEffect(() => {
            const handleKeyDown = (event) => {
              if (event.key === "ArrowUp") {
                event.preventDefault();
                navigatePlayer(-1);
              } else if (event.key === "ArrowDown") {
                event.preventDefault();
                navigatePlayer(1);
              } else if (event.key === "Enter") {
                event.preventDefault();
                handleInsert();
              }
            };
          
            document.addEventListener("keydown", handleKeyDown);
          
            return () => {
              document.removeEventListener("keydown", handleKeyDown);
            };
          }, [suggestedPlayers, selectedPlayer]);
          
          const navigatePlayer = (direction) => {
            const currentIndex = suggestedPlayers.findIndex(
              (player) => player === selectedPlayer
            );
            let newIndex = currentIndex + direction;
            if (newIndex < 0) {
              newIndex = suggestedPlayers.length - 1;
            } else if (newIndex >= suggestedPlayers.length) {
              newIndex = 0;
            }
            setSelectedPlayer(suggestedPlayers[newIndex]);
          };

          const [finalLevel, setFinalLevel] = useState("")
          const [finalLevelURL, setFinalLevelURL] = useState()
        
          useEffect(() => {
            if (streak<4){
              setFinalLevel("Oops you just missed a layup")
              setFinalLevelURL(livello1)
            } else if (streak>=4 && streak<7){
              setFinalLevel("Naah you can't brick an open three like this")
              setFinalLevelURL(livello2)
            }else if (streak>=7 && streak<13){
              setFinalLevel("You had a solid game, keep it up")
              setFinalLevelURL(livello3)
            }else if (streak>=13 && streak<18){
              setFinalLevel("You are splashing threes like Stephen Curry man")
              setFinalLevelURL(livello4)
            }else if (streak>=18 && streak<23){
              setFinalLevel("Congratulations, you are the LeBron James of our site")
              setFinalLevelURL(livello5)
            }else{
              setFinalLevel("You are just the G.O.A.T")
              setFinalLevelURL(livello6)
            }
          },[streak])

          const skippo = () => {
            let newIndex;
            let newRandomPlayer;
            do{
              newIndex = Math.floor(Math.random() * easyFilter.length);
              newRandomPlayer = easyFilter[newIndex]
          }while (usedPlayers.includes(newRandomPlayer))

          setRandomPlayer(newRandomPlayer);
          setSkip(!skip)
          setSkipsNum(0)
          }


        function refreshPage() {
            window.location.reload(false);
        }

  return (
    <div>
        <Navbar></Navbar>
        {gameOver && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div className="fine"><div className="cry"><img className="cryImg" src={finalLevelURL} /></div>
                <div className="testoFine">
                <p className="spiegazione">{finalLevel}. Your final score is {streak}.<br></br>
                <strong>{randomPlayer.soprannomi[pos]} is {randomPlayer.firstName} {randomPlayer.lastName}'s nickname.</strong>
                </p>
                <br></br>
                <button className="bottoneHow1" onClick={refreshPage}>New Game</button><button style={{color: "transparent", backgroundColor: "transparent", cursor: "default"}}>OMG an easter egg</button>
                <Link to="/"><button className='bottoneHow2'><HomeRoundedIcon /><br></br>Home</button></Link></div></div>}
            </div>
          </div>
        )}
        <div className='nickname'>
            <h1 className='streak2' style={{color: "white"}}><b>SCORE: {streak}</b></h1>
            <div className='inputNickname'>
                <div className='boh'></div>
                {randomPlayer && (
                    <div className='soprannome'>
                    <p>"{randomPlayer.soprannomi[pos]}"</p>
                </div>
                )}
            <div className='inputAre'>
                {searchQuery && suggestedPlayers.length > 0 && (
                    <ul className="suggested-players">
                        {suggestedPlayers.map((player, index) => (
                            <li className={`player ${player === selectedPlayer ? 'selected' : ''}`} key={index} onClick={() => handlePlayerSelect(player)}>
                                {`${player.firstName} ${player.lastName}`}
                            </li>
                        ))}
                    </ul>
                )}
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="guess the player..."
                    className='inputGiocatori'
                />
            </div>
            <button onClick={handleInsert}>Insert</button>
            </div>
            <div className="lives1">
                {Array.from({ length: lives }).map((_, index) => (
                    <SportsBasketballTwoToneIcon style={{color: "orange"}} key={index} />
                ))}
            </div>
            <div className='skipNick'>
              <button onClick={skippo} className='skipButton' disabled={!skip}><SkipNextSharpIcon></SkipNextSharpIcon></button>
              <p className='numeroDegliSkip'>Skips: {skipsNum}</p>
            </div>
        </div>
        <Footer></Footer>
    </div>
  )
}

export default Nickname