import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAXcVu9Frl6hKH9XhQPqYOc4z7q6CivMj4",
    authDomain: "basketballcurryosity.firebaseapp.com",
    projectId: "basketballcurryosity",
    storageBucket: "basketballcurryosity.appspot.com",
    messagingSenderId: "216315385399",
    appId: "1:216315385399:web:07bf9f27695fa40dfdd1b2",
    measurementId: "G-JSR577TB4Q"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
