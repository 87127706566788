import React from "react";
import { players } from "../players";
import "../styles/Easy.css";
import { useState, useEffect, useRef } from "react";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsBasketballTwoToneIcon from '@mui/icons-material/SportsBasketballTwoTone';
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';
import James from '../assets/James.jpg'
import giphy from '../assets/giphy (1).gif'
import BasketballCourt from '../assets/BasketballCourt.jpg'
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import livello1 from '../assets/livello1.jpg'
import livello2 from '../assets/livello2.jpg'
import livello3 from '../assets/livello3.jpg'
import livello4 from '../assets/livello4.jpg'
import livello5 from '../assets/livello5.jpg'
import livello6 from '../assets/livello6.jpg'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link } from "react-router-dom";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import firebase from '../firebase';


/**
 * @typedef {Object} Player
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} img
 * @property {string[]} teams
 * @property {string} level
 */

function Easy() {
  const filtro = players.filter((player) => player.level.length > 0);
  const easyFilter = players.filter((player) => player.level === "easy");
  const easymediumFilter = players.filter((player) => player.level === "easy" || player.level === "medium")
  const mediumFilter = players.filter((player) => player.level === "medium");
  const mediumhardFilter = players.filter((player) => player.level === "medium" || player.level === "hard")
  const hardFilter = players.filter((player) => player.level === "hard");
  const extrahardFilter = players.filter((player) => player.level === "extrahard");
  const threeFilter = players.filter((player) => player.teams.length > 1)
  const x = Math.floor(Math.random() * easyFilter.length);
  const [randomPlayer, setRandomPlayer] = useState(null);
  const inputRef = useRef(null);
  const [counterTre, setCounterTre] = useState(0)

  const [squadreIndovinate, setSquadreIndovinate] = useState([]);
  const [completedPlayers, setCompletedPlayers] = useState(0);
  const [squadreGiàInserite, setSquadreGiàInserite] = useState([]);


  const [correctGuesses, setCorrectGuesses] = useState(0);
  const [wrongGuesses, setWrongGuesses] = useState(0);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  const [remainingHearts, setRemainingHearts] = useState(3);

  const [linko, setLinko] = useState("");

  const [uniqueCount, setUniqueCount] = useState(0);

  const [visualizedPlayers, setVisualizedPlayers] = useState([]);

  


  useEffect(() => {
    const randomPlayer = easyFilter[x];
    setRandomPlayer(randomPlayer);
    const first4 = randomPlayer.lastName.substring(0,5).toLocaleLowerCase()
    const first2 = randomPlayer.firstName.substring(0,2).toLocaleLowerCase()
    const linko = first4 + first2
    setLinko(linko)
    console.log(linko)
    const countUniqueElements = (array) => {
      const uniqueElements = {};
      for (const element of array) {
        uniqueElements[element] = true;
      }
      return Object.keys(uniqueElements).length;
    };
    const count = countUniqueElements(randomPlayer.teams);
    setUniqueCount(count);
  }, []);

  const firestore = firebase.firestore();
  const [data, setData] = useState([]);

  const fetchData = async () => {
      const dataRef = firestore.collection('players');
      const snapshot = await dataRef.get();
      const fetchedData = snapshot.docs.map(doc => doc.data());
      const sortedData = fetchedData.sort((a, b) => b.punteggio - a.punteggio); // Ordina per punteggio decrescente
      setData(sortedData);
      console.log(sortedData);
  };
    useEffect(() => {
        fetchData();
    }, [firestore]);

  const squadreDisponibili = [
    "Atlanta Hawks",
    "Boston Celtics",
    "Brooklyn Nets",
    "Charlotte Hornets",
    "Chicago Bulls",
    "Cleveland Cavaliers",
    "Dallas Mavericks",
    "Denver Nuggets",
    "Detroit Pistons",
    "Golden State Warriors",
    "Houston Rockets",
    "Indiana Pacers",
    "Los Angeles Clippers",
    "Los Angeles Lakers",
    "Memphis Grizzlies",
    "Miami Heat",
    "Milwaukee Bucks",
    "Minnesota Timberwolves",
    "New Orleans Pelicans",
    "New York Knicks",
    "Oklahoma City Thunder",
    "Orlando Magic",
    "Philadelphia 76ers",
    "Phoenix Suns",
    "Portland Trail Blazers",
    "Sacramento Kings",
    "San Antonio Spurs",
    "Toronto Raptors",
    "Utah Jazz",
    "Washington Wizards"
  ];

  const [inputValue, setInputValue] = useState("");
  const [squadreSuggerite, setSquadreSuggerite] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [silverSkipper, setSilverSkipper] = useState(true)
  const [counterSilver, setCounterSilver] = useState(2)
  const [goldenSkipper, setGoldenSkipper] = useState(false)
  const [counterGolden, setCounterGolden] = useState(1)
  const [dp, setDp] = useState("none");

  const [verificaMessages, setVerificaMessages] = useState([]);

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);


  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertVisible(true);
  
    // Imposta un timeout per nascondere l'avviso dopo un certo periodo di tempo (ad esempio, 3 secondi)
    setTimeout(() => {
      setAlertVisible(false);
    }, 3000); // Nascondi l'avviso dopo 3 secondi (puoi regolare questo valore)
  };

  function CustomAlert({ message, visible }) {
    return visible ? (
      <div className="custom-alert">
        <p>{message}</p>
      </div>
    ) : null;
  }
  
  


  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setInputValue(value);

    const squadreFiltrate = squadreDisponibili.filter((squadra) => {
      const squadraFormattata = squadra.toLowerCase();
      const paroleSquadra = squadraFormattata.split(" ");
      const valoreLowerCase = value.toLowerCase();

      if (squadraFormattata.startsWith(valoreLowerCase)){
        return true
      }
      

      // Controlla se la seconda parola della squadra inizia con il valore di ricerca
      if (
        paroleSquadra.length > 1 &&
        paroleSquadra[1].startsWith(valoreLowerCase)
      ) {
        return true;
      }

      if (
        paroleSquadra.length > 2 &&
        paroleSquadra[2].startsWith(valoreLowerCase)
      ) {
        return true;
      }

      if (paroleSquadra[0].startsWith(valoreLowerCase)) {
        return true;
      }

      return false;
    });

    setSquadreSuggerite(squadreFiltrate);
    setSelectedSuggestionIndex(-1); // Resetta l'elemento selezionato quando si modifica l'input.
    setShowDropdown(true);
  };

  const handleKeyDown = (e) => {
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    setSelectedSuggestionIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  } else if (e.key === 'ArrowDown') {
    e.preventDefault();
    setSelectedSuggestionIndex((prevIndex) =>
      prevIndex < squadreSuggerite.length - 1 ? prevIndex + 1 : prevIndex
    );
  } else if (e.key === 'Enter') {
    e.preventDefault();
    if (selectedSuggestionIndex !== -1) {
      handleSquadraClick(squadreSuggerite[selectedSuggestionIndex]);
    }
  }
};


  const handleSquadraClick = (squadra) => {
    setInputValue(squadra);
    setShowDropdown(false);
  };

  /**
   * Funzione per verificare i dettagli di un giocatore
   * @param {string} squadra - Il nome della squadra
   * @param {Player} giocatore - L'oggetto giocatore che contiene i dettagli del giocatore
   */

  const getNextRandomPlayer = () => {
    let newIndex;
    let newRandomPlayer;
  
    do{
    if (completedPlayers >= 0 && completedPlayers <= 4) {
      if (counterTre < 4){
        newIndex = Math.floor(Math.random() * easyFilter.length);
        newRandomPlayer = easyFilter[newIndex]
      }else{
        newIndex = Math.floor(Math.random() * threeFilter.length);
        newRandomPlayer = threeFilter[newIndex]
        setCounterTre(0)
      }
    } else if (completedPlayers >= 5 && completedPlayers <= 6) {
      if (counterTre < 4){
        newIndex = Math.floor(Math.random() * easymediumFilter.length);
        newRandomPlayer = easymediumFilter[newIndex]
      }else{
        newIndex = Math.floor(Math.random() * threeFilter.length);
        newRandomPlayer = threeFilter[newIndex]
        setCounterTre(0)
      }
    } else if (completedPlayers >= 7 && completedPlayers <= 9) {
      if (counterTre < 4){
        newIndex = Math.floor(Math.random() * mediumFilter.length);
        newRandomPlayer = mediumFilter[newIndex]
      }else{
        newIndex = Math.floor(Math.random() * threeFilter.length);
        newRandomPlayer = threeFilter[newIndex]
        setCounterTre(0)
      }
    } else if (completedPlayers >= 10 && completedPlayers <= 13) {
      newIndex = Math.floor(Math.random() * mediumhardFilter.length);
      newRandomPlayer = mediumhardFilter[newIndex]
    } else if (completedPlayers >= 14 && completedPlayers <= 16) {
      newIndex = Math.floor(Math.random() * hardFilter.length);
      newRandomPlayer = hardFilter[newIndex]
    } else if (completedPlayers >= 17 && completedPlayers <= 20) {
      newIndex = Math.floor(Math.random() * extrahardFilter.length);
      newRandomPlayer = extrahardFilter[newIndex]
    } else {
      if (counterTre < 4){
        newIndex = Math.floor(Math.random() * filtro.length);
        newRandomPlayer = filtro[newIndex]
      }else{
        newIndex = Math.floor(Math.random() * threeFilter.length);
        newRandomPlayer = threeFilter[newIndex]
      }
    }
  } while (visualizedPlayers.includes(newRandomPlayer))

  setVisualizedPlayers([...visualizedPlayers, newRandomPlayer]);

  if (newRandomPlayer.teams.length === 1){
    setCounterTre(counterTre + 1)
  }else{
    setCounterTre(0)
  }

    const countUniqueElements = (array) => {
      const uniqueElements = {};
      for (const element of array) {
        uniqueElements[element] = true;
      }
      return Object.keys(uniqueElements).length;
    };
    const count = countUniqueElements(newRandomPlayer.teams);
    setUniqueCount(count);
  
    setDisabled(false);
    setDp("none");

    return newRandomPlayer;
  };
  

  useEffect(() => {
    if (
      randomPlayer &&
      squadreIndovinate.length === uniqueCount
    ) {
      setDisabled(true);
      setDp("block");
      setCompletedPlayers(completedPlayers + 1);
      setSilverSkipper(true)
      setSquadreIndovinate([]);
    setCorrectGuesses(0);
    setCurrentStreak(0);
    setVerificaMessages([]);
    setRandomPlayer(getNextRandomPlayer());
    setSilverSkipper(true)
    setSquadreGiàInserite([]);
    const first4 = randomPlayer.lastName.substring(0,5).toLocaleLowerCase()
    const first2 = randomPlayer.firstName.substring(0,2).toLocaleLowerCase()
    const linko = first4 + first2
    setLinko(linko)
    console.log(linko)
    }
  }, [squadreIndovinate, randomPlayer]);

  useEffect(() => {
    if (
      randomPlayer &&
      uniqueCount >= 3 &&
      uniqueCount <= 5 &&
      squadreIndovinate.length === (uniqueCount - 1) &&
      counterSilver != 0
    ){
      setSilverSkipper(false)
    }
    if (
      randomPlayer &&
      uniqueCount >=6 &&
      uniqueCount <=8 &&
      squadreIndovinate.length === (uniqueCount -2) &&
      counterSilver != 0
    ){
      setSilverSkipper(false)
    }
    if (
      randomPlayer &&
      uniqueCount >=9 &&
      uniqueCount <=15 &&
      squadreIndovinate.length === (uniqueCount -3) &&
      counterSilver != 0
    ){
      setSilverSkipper(false)
    }
    
  },[squadreIndovinate])

  const verifica = (squadra, giocatore) => {
    if (squadreGiàInserite.includes(squadra)) {
      showAlert("You already insert this team");
      return;
    }
    const message = giocatore.teams.includes(squadra) ? (
      <span className="guessesSpunta">
        {squadra} <br></br> <DoneRoundedIcon className="spunte" style={{ color: "white" }} />
      </span>
    ) : (
      <span className="guessesSpunta">
        {squadra} <br></br> <CloseRoundedIcon className="spunte" style={{ color: "red" }} />
      </span>
    );

    if (
      !squadreGiàInserite.includes(squadra) &&
      squadreDisponibili.includes(squadra)
      ) {
      setVerificaMessages([...verificaMessages, message]);
      setSquadreGiàInserite([...squadreGiàInserite, squadra]);
    }

    if (
      squadreDisponibili.includes(squadra) &&
      !verificaMessages.includes(message)
    ){
      setVerificaMessages([...verificaMessages, message])
    }else{
      showAlert("insert an available team or a team that you haven't already insert")
    }

    if (
      giocatore.teams.includes(squadra) &&
      !squadreIndovinate.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setSquadreIndovinate([...squadreIndovinate, squadra])
    }

    if (giocatore.teams.includes(squadra)) {
      setCorrectGuesses(correctGuesses + 1);
      setCurrentStreak(currentStreak + 1);
    } else if (!giocatore.teams.includes(squadra) &&
      squadreDisponibili.includes(squadra)
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setCurrentStreak(0);
    }

    // Controlla se hai indovinato tutte le squadre del giocatore
    if (squadreIndovinate.length === giocatore.teams.length) {
      // Hai indovinato tutte le squadre, aggiungi il giocatore successivo
      setRandomPlayer(getNextRandomPlayer());
      setCorrectGuesses(0);
    }
  };

  useEffect(() => {
    if (wrongGuesses == 3) {
      setGameOver(true);
      setRemainingHearts(3 - wrongGuesses)
      handleAddPlayer();
    } else {
      setRemainingHearts(3 - wrongGuesses)
    }

  }, [wrongGuesses]);

  const handleAddPlayer = async () => {
    console.log('eccolo');
    console.log(nicknameInput);
        try {
            await firestore.collection('players').add({
                name: nicknameInput,
                punteggio: completedPlayers
            });
            fetchData();
        } catch (error) {
            console.error("Errore nell'aggiunta del giocatore:", error);
        }
    }

  const handleButtonClick = () => {
    if (inputValue.trim() !== "") {
      verifica(inputValue, randomPlayer);
      setInputValue("");
    }
  };
  

  const handleNextPlayerClick = () => {
    setSquadreIndovinate([]);
    setCorrectGuesses(0);
    setCurrentStreak(0);
    setVerificaMessages([]);
    setRandomPlayer(getNextRandomPlayer());
    setSilverSkipper(true)
    setSquadreGiàInserite([]);
    const first4 = randomPlayer.lastName.substring(0,5).toLocaleLowerCase()
    const first2 = randomPlayer.firstName.substring(0,2).toLocaleLowerCase()
    const linko = first4 + first2
    setLinko(linko)
    console.log(linko)
  };

  const handleNextPlayerClickSilver = () => {
    setSquadreIndovinate([]);
    setCorrectGuesses(0);
    setCurrentStreak(0);
    setVerificaMessages([]);
    setRandomPlayer(getNextRandomPlayer());
    setSilverSkipper(true)
    setSquadreGiàInserite([]);
    setCounterSilver(v => (v-1))
    const first4 = randomPlayer.lastName.substring(0,5).toLocaleLowerCase()
    const first2 = randomPlayer.firstName.substring(0,2).toLocaleLowerCase()
    const linko = first4 + first2
    setLinko(linko)
    console.log(linko)
  };

  const handleNextPlayerClickGolden = () => {
    setSquadreIndovinate([]);
    setCorrectGuesses(0);
    setCurrentStreak(0);
    setVerificaMessages([]);
    setRandomPlayer(getNextRandomPlayer());
    setSilverSkipper(true)
    setSquadreGiàInserite([]);
    setGoldenSkipper(true)
    setCounterGolden(0)
    const first4 = randomPlayer.lastName.substring(0,5).toLocaleLowerCase()
    const first2 = randomPlayer.firstName.substring(0,2).toLocaleLowerCase()
    const linko = first4 + first2
    setLinko(linko)
    console.log(linko)
  };

  const [howtoplay, setHowtoplay] = useState(true)

  const htpChange = () => {
    setHowtoplay(false)
  }

  const [finalLevel, setFinalLevel] = useState("")
  const [finalLevelURL, setFinalLevelURL] = useState()

  useEffect(() => {
    if (completedPlayers<3){
      setFinalLevel("Oops you just missed a layup")
      setFinalLevelURL(livello1)
    } else if (completedPlayers>=3 && completedPlayers<7){
      setFinalLevel("Naah you can't brick an open three like this")
      setFinalLevelURL(livello2)
    }else if (completedPlayers>=7 && completedPlayers<11){
      setFinalLevel("You had a solid game, keep it up")
      setFinalLevelURL(livello3)
    }else if (completedPlayers>=11 && completedPlayers<16){
      setFinalLevel("You are splashing threes like Stephen Curry man")
      setFinalLevelURL(livello4)
    }else if (completedPlayers>=16 && completedPlayers<23){
      setFinalLevel("Congratulations, you are the LeBron James of our site")
      setFinalLevelURL(livello5)
    }else{
      setFinalLevel("You are just the G.O.A.T")
      setFinalLevelURL(livello6)
    }
  },[completedPlayers])

  const refreshPage = () => {
    window.location.reload(false);
  } 

  const [goldenTrue, setGoldenTrue] = useState(false)
  const [silverTrue, setSilverTrue] = useState(false)

  const changeGolden = () => {
    setGoldenTrue(!goldenTrue)
  }

  const changeSilver = () => {
    setSilverTrue(!silverTrue)
  }

  const [nickname, setNickname] = useState(true);

  const playAfterNickname = () => {
    setNickname(!nickname);
  }

  const [nicknameInput, setNicknameInput] = useState('');
  
  const handleNicknameInputChange = (event) => {
    setNicknameInput(event.target.value); // Aggiorna la variabile di stato con il valore dell'input
  };

  const playAfterNicknameInput = () => {
    console.log("Nickname Input Value:", nicknameInput); // Esempio di utilizzo del valore dell'input
    // setNicknameInput(); // Resettare il valore dell'input dopo l'uso
    setNickname(!nickname);
  };

  return (
    <div>
      <Navbar></Navbar>
      <div className="easy">
      {nickname && (
          <div className='popup-overlay'>
            <div className='popup'>
              <p>Insert your nickname:</p>
              <input 
              type="text" 
              value={nicknameInput}
              onChange={handleNicknameInputChange}
            />
                <button onClick={playAfterNicknameInput}>Let's play</button>
            </div>
          </div>
        )}
        {silverTrue && (
          <div className='popup-overlay'>
              <div className='popup'>
                  {<div>
                    <p className="spiegazione">It’s a particular skip that you can only use for specific circumstances: <br></br>
1) the player has played in at least 3 teams <br></br>
2) you have guessed most of the teams but struggle on some <br></br>
So you can use it if you guess: <br></br>
All but one team for players who played for 3-5 teams <br></br>
All but two teams for players who played for 6-8 teams <br></br>
All but three teams for players who played for 9 or more teams
</p><br></br>
                    <button className="bottoneHow" style={{marginTop: `-5px`}} onClick={changeSilver}>DONE</button>
                  </div>}
              </div>
          </div>
          )}
        {goldenTrue && (
          <div className='popup-overlay'>
              <div className='popup'>
                  {<div>
                    <p className="spiegazione">Simple tool to use when you don’t know some players' teams <br></br>
You just have one <br></br>
Make sure you don’t waste it</p><br></br>
                    <button className="bottoneHow" style={{marginTop: `-5px`}} onClick={changeGolden}>DONE</button>
                  </div>}
              </div>
          </div>
          )}

        {gameOver && (
          <div className='popup-overlay'>
            <div className='popup'>
                {<div className="fine"><div className="cry"><img className="cryImg" src={finalLevelURL} /></div>
                <div className="testoFine">
                <p className="spiegazione">{finalLevel}. Your final score is {completedPlayers}.<br></br><strong>{randomPlayer.firstName} {randomPlayer.lastName} has played for: {randomPlayer.teams.join(", ")}</strong></p>
                <br></br>
                <button className="bottoneHow1" onClick={refreshPage}>New Game</button><button style={{color: "transparent", backgroundColor: "transparent", cursor: "default"}}>OMG an easter egg</button>
                <div className="scores-container">
                  <h1>SCORES</h1>
                {data.map((player, index) => (
                                <div key={index}>
                                    <p>{player.name} - {player.punteggio}</p>
                                </div>
                            ))}
                </div> 
                <Link to="/"><button className='bottoneHow2'><HomeRoundedIcon /><br></br>Home</button></Link></div></div>}
            </div>
          </div>
        )}
        <div className="custom-alert-container">
          <CustomAlert message={alertMessage} visible={alertVisible} />
        </div>
        {/*<h2>Easy Players:</h2>
      <ul>
        {easyFilter.map((player, index) => (
          <li key={index}>
            {player.firstName} {player.lastName}
          </li>
        ))}
      </ul> */}
      {randomPlayer && (
        <div className="playerArea">
          <div className='playerImage' style={{backgroundImage: `url(${randomPlayer.img})`}}>
          </div>
          <div className="coloreDati">
          <div className="playerName">
            <h1>
              <b>{randomPlayer.firstName} {randomPlayer.lastName}</b>
              {/*<br></br>
              level: {randomPlayer.level}*/}
            </h1>
          </div>
          <div className="numberOfTeams">
            <p>Played for <br></br> {uniqueCount} teams</p>
          </div>
          </div>
          {/*<p>{randomPlayer.teams}</p>*/}
        </div>
      )}
        <div className="hearts">
          {Array.from({ length: 3 }, (_, index) => (
            <span
              key={index}
              className={`heart ${index < remainingHearts ? 'filled' : 'empty'}`}
            >
              <SportsBasketballTwoToneIcon style={{color: "orange"}} />
            </span>
          ))}
        </div>
        <div className="streak">
          <h2><b>SCORE: {completedPlayers}</b></h2>
        </div>
        <div>
          {!gameOver ? (
            <div>
            <div className="guessing">
              <div className="inputArea">
              <input
                className="inputTextArea"
                type="text"
                value={inputValue}
                placeholder="guess the teams..."
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleButtonClick();
                  }
                }}
                ref={inputRef} // Aggiungi il riferimento all'elemento di input
              />
                {showDropdown && inputValue !== '' && squadreSuggerite.length > 0 && (
                  <ul>
                    {squadreSuggerite.map((squadra, index) => (
                      <li
                        className={`tendina ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                        key={index}
                        onClick={() => handleSquadraClick(squadra)}
                      >
                        {squadra}
                      </li>
                    ))}
                  </ul>
                )}
                <button className="insert" onClick={handleButtonClick} disabled={disabled}>
                  Insert
                </button>
              </div>
              <p style={{color: "silver"}}><b>Your guesses:</b></p>
              <div className="guessArea">
                {verificaMessages.map((message, index) => (
                  <div>
                    <b><p key={index}>{message}</p></b>
                  </div>
                ))}
              </div>
            </div>
              <div className="skips">
                <div className="gridSkips">
                  <div className="questionMark">
                    <div className="question" onClick={changeSilver}>
                      <LiveHelpRoundedIcon style={{color: "silver"}}></LiveHelpRoundedIcon>
                    </div>
                    <div className="question" onClick={changeGolden}>
                      <LiveHelpRoundedIcon style={{color: "yellow"}}></LiveHelpRoundedIcon>
                    </div>
                  </div>
                  <div>
                  <div className="skippers">
                    <button className="silver" disabled={silverSkipper} onClick={handleNextPlayerClickSilver}><SkipNextSharpIcon /><SkipNextSharpIcon /></button>
                    <p style={{color: "silver"}}>Silver skips: {counterSilver}</p>
                  </div>
                  <div className="skippers">
                    <button className="golden" disabled={goldenSkipper} onClick={handleNextPlayerClickGolden}><SkipNextSharpIcon /><SkipNextSharpIcon /></button>
                    <p style={{color: "yellow"}}>Golden skips: {counterGolden}</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p>Game Over! You did too much errors.</p>
              <p>Your final score is {completedPlayers}</p>
              <button onClick={refreshPage}>New game</button>
            </div>
          )}
          <div>
            <button
              style={{ display: dp }}
              onClick={handleNextPlayerClick}
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Easy;
