import logo from './logo.svg';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import Easy from './pages/Easy';
import Medium from './pages/Medium';
import Hard from './pages/Hard';
import ExtraHard from './pages/ExtraHard';
import Career from './pages/Career';
import { Helmet } from 'react-helmet';
import Nickname from './pages/Nickname';
import PrevNickname from './pages/PrevNickname';
import PrevMedium from './pages/PrevMedium';
import PrevEasy from './pages/PrevEasy';
import PrevCareer from './pages/PrevCareer';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className='App'>
      <Helmet>
        <title>Curryosity</title>
      </Helmet>
      <Router>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/easy' element={<Easy />}/>
        <Route path='/medium' element={<Medium />}/>
        <Route path='/hard' element={<Hard />}/>
        <Route path='/extrahard' element={<ExtraHard />}/>
        <Route path='/career' element={<Career />} />
        <Route path='/nickname' element={<Nickname />} />
        <Route path='/prevnickname' element={<PrevNickname />} />
        <Route path='/prevmedium' element={<PrevMedium />} />
        <Route path='/preveasy' element={<PrevEasy />} />
        <Route path='/prevcareer' element={<PrevCareer />} />
      </Routes>
      </Router>
      <Analytics />
    </div>
  );
}

export default App;
