export const topPlayers = [
    {
      firstName: "Kareem",
      lastName: "Abdul-Jabbar",
      teams: ["Milwaukee Bucks", "Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcfM9jLcTZc5ijdtCFWp2b7Wi9zG_27PobZA82_DtFskqNjLTL_LOWFNRPg0yzhljTPaqNemR2kal6IQKJz7Xa76lsNkwjBRWVs0Cs_SGiOO4bhP7gPXZ-CsPFz3TAfpOinuDhTgbnMADvIOeIJZ85m=w127-h166-s-no?authuser=4"
    },
    {
      firstName: "Ray",
      lastName: "Allen",
      teams: [
        "Milwaukee Bucks",
        "Oklahoma City Thunder",
        "Boston Celtics",
        "Miami Heat"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdVq8cmBTccD8aStHwn_QSvRys29UuzaAAjQVzPNsK9maaGsi-UCucS62Z1dsz3mgYuTlvDl-6ZjfJYo6WLx2oWLaLVz0kJerqKMr3zn_C94o-CdGFpCZX0LRJtBmNxoRjz2hfX2utTku_7ua51hqsO=w115-h150-s-no?authuser=4"
    },
    {
      firstName: "Giannis",
      lastName: "Antetokounmpo",
      teams: ["Milwaukee Bucks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcElmIJ6UGcXhaKlgqvohg2cgWR0gwhEIWIKq_b0XdqVHmJkolzeUUOm7lhCta-YUb-JHGeHrsMxQrfPPzMzDmEk6T-ntjVJUw7tEV5VMZeQ-hhxrwxESr6eAdYl5hXbYCRE3SGKCzhleJDxgNGGYFt=w124-h138-s-no?authuser=4"
    },
    {
      firstName: "Carmelo",
      lastName: "Anthony",
      teams: [
        "Denver Nuggets",
        "New York Knicks",
        "Oklahoma City Thunder",
        "Portland Trail Blazers",
        "Los Angeles Lakers",
        "Houston Rockets"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcaMde9f5fJawPAZh2gio44niiBzxPoL3B-iUcQOtHcYbihoFixXBnIlo16JbLHhQNvUzqLQlrK3oA7DzLr27Gz71owHr5QIjXpUfZyVtwStXf3zm08D-eMIGoUAMSlRnV4CtPGRWxfGkbK8DP63BeH=w131-h150-s-no?authuser=4"
    },
    {
      firstName: "Nate",
      lastName: "Archibald",
      teams: [
        "Brooklyn Nets",
        "Boston Celtics",
        "Milwaukee Bucks",
        "Sacramento Kings"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfuoBY6g_ongwK7cypJGOo59KUYLj-vOCxmJ_4nXAEQJSC6g5aY39j3iXNomkrdAE4zj1SMvRe4pZ523TK9EOLVNOlP0qAxoGoSI-J0qIoo_TMVwrrBBH6pJ3ZHi8CfeOry7sQJpRfJyXZfS9hcRQr2=w123-h138-s-no?authuser=4"
    },
    {
      firstName: "Paul",
      lastName: "Arizin",
      teams: ["Golden State Warriors"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdBRMjmkMmxvjglDG2jdb7vThH6PvEOp-QzNB8uEp6g7uLoL5qK5LQRJzqjQUcSWzAQ1nwJzkrycJmXoDU8wx4WDepUPmGHnMeJ94wwO4fgMMJOxar-zbhMk02P3GzjSjusK62wMC0Z5jwSoA6TGHZK=w128-h144-s-no?authuser=4"
    },
    {
      firstName: "Charles",
      lastName: "Barkley",
      teams: ["Philadelphia 76ers", "Phoenix Suns", "Houston Rockets"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcS0syhXnfqLurOk6hZ-G8g5Hm8ZGYLk3EPdnkTkgQ3EJnRMhbElprsp_rwXT5TPpxWVQ7uDuN09VMgnopnrf5qElOtuezH9Bx356JAnqjLjIg5Eky40MCMHPcvT7OyzeVbfOe1Ke_FlMyothHoIAhq=w124-h150-s-no?authuser=4"
    },
    {
      firstName: "Rick",
      lastName: "Barry",
      teams: ["Golden State Warriors", "Houston Rockets"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfzrC-6chFqKIVQL4Qgj7Ie5BqRKt9tow-Gy9OZZU7sbvLZxXrFkcOQlZiBTtQeVouiU8gRUnnLUIgFiaCZd2qZAx9EwhsL9SBjy8a4xLKUvoANXoEF2EV0NzOAw3gpYS31xSCXl03V4TJUX8gbva4m=w121-h158-s-no?authuser=4"
    },
    {
      firstName: "Elgin",
      lastName: "Baylor",
      teams: ["Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdkzovfacX36YnJhuIHAVW534f5_18RGqcb7tiWaO66R6JraSD73ULqnclrMPQVf-viFEXD3AmPpx2vGc0zafsAkTNU9BlYhEiMAdo_DuTZz__-lEbXTuvJkGrxjxGfs-HNs5TVya3PZVgAH28DEdni=w121-h168-s-no?authuser=4"
    },
    {
      firstName: "Dave",
      lastName: "Bing",
      teams: ["Detroit Pistons", "Washington Wizards", "Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeZLTit6oRPCYXrXxFlbI72_2JvS9ap8BNyyXpeVDWBtSVJQ3GE3ocqr-7AP09wkUfGHSLSHfW0qOWw4wCVKpBub4MSj1i_I4hspfKosSsvY5iv76ee6cYh6HG-dLtQg4riPAnl2lAVolIZ-6xwTfAw=w114-h117-s-no?authuser=4"
    },
    {
      firstName: "Larry",
      lastName: "Bird",
      teams: ["Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHceh59M_CEIuVQxW7e0YttjQqNeoXWumS368xSr8fpqwvqhJKvQ-kNQ9Qb_khh3xdFN-STwqsBj_qyD1OW79clNf2r02ZezmAQ_7kX3qg6BPjU7n98ljZyOCZeX2czrSjX_j4qTi0BebSu3klS-HJfV=w123-h164-s-no?authuser=4"
    },
    {
      firstName: "Kobe",
      lastName: "Bryant",
      teams: ["Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdrApOKIrw5QhhZI1FtDUqCZuW5_3SXiu59oPCLYy096R-0WAtZv_pElBSjgum1KtocGJWosCFWEoQNa5aKPyKacMc5772GQG7LK8WO8_2yMVl63c04dNSt0YOJ6IBAPzoL3cp67mKhB__MYYhc3MOC=w116-h141-s-no?authuser=4"
    },
    {
      firstName: "Wilt",
      lastName: "Chamberlain",
      teams: ["Golden State Warriors", "Philadelphia 76ers", "Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfhmLQuHpmxLxlPEVDn4YQ-n7I0ReEgkDMGgJPAvZOEErOmHPFbveJNNphAdB1wR4QGF_VCzJ6uglFK9BOfD7hDCgvFdT7W80pPSGdctEgMiHQmNH68yWAnkkq4RCuWmGNIYEf0_4BW4S8VcyAGthik=w125-h157-s-no?authuser=4"
    },
    {
      firstName: "Bob",
      lastName: "Cousy",
      teams: ["Boston Celtics", "Sacramento Kings"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdQMrKlNkilWXKPZHSa5LQycCqyR56bDWdzqvLWRUrDMbdlQoeQZ0ChmmEVAD2CO4q48HbaYrAcmCgRpFMkLIR9PM8DGZvu90fv_IDIySUwzgyJ2ksxnTfxXewOwl5k7SaCObs_eQeY2wOz1uD1Wlzb=w111-h172-s-no?authuser=4"
    },
    {
      firstName: "Dave",
      lastName: "Cowens",
      teams: ["Boston Celtics", "Milwaukee Bucks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHf1qRu-l4HVw3OPBwhZt9TWDmDe8gEOlBOfNu_nXCR0wbAApUl_P2DzfXhTebqXj85aebZaW3Q-UsS5fno_P-wqDXpcdUCkLN6Z4c8AshTS2-3hcSowfzNSaPcIQjQSIO2xTnpmCFS50WrqA83vficd=w115-h173-s-no?authuser=4"
    },
    {
      firstName: "Billy",
      lastName: "Cunningham",
      teams: ["Philadelphia 76ers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHe9nr87LpdVF0LsJL8VxrVg7RcJDAgClXbOULuKADcggxnpysYkReaXaV2g6SEQpr4sw7q2LtaPx8H3eYYxvnydHaTcvtxVn082i0zstAs8WG_gINf1yTKWotOAznXUgqBgolYULUqq5nfjgwHchs_0=w115-h163-s-no?authuser=4"
    },
    {
      firstName: "Stephen",
      lastName: "Curry",
      teams: ["Golden State Warriors"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHd13hLPdWyESqyW3aa6Ie5IvJ4KmcWfECcnMAi1LP1qQgvYj9zJAklTAtc_vMEZqgDlg5L2hdcNccobF_fb6N5pM4-Rk92jUVmmW_JfIYbR12XHlN0fpKfS70oC4rUTssjX7wSRg71lO7OY5HIppqC7=w116-h149-s-no?authuser=4"
    },
    {
      firstName: "Anthony",
      lastName: "Davis",
      teams: ["New Orleans Pelicans", "Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHd9JimnB6Y3cLY6eWwGch4Ful3L2HCssHlxJBO6NcJaBSASaGecFmV5q05VyTASFEam4c_5FovZiy8gMxlDh_onTYQguwFAz6_tyzOcTOKzqK0AVA4L-6rtXx-6rC5poTLRMQ9DWbIGTRUcRWciz12_=w120-h144-s-no?authuser=4"
    },
    {
      firstName: "Dave",
      lastName: "DeBusschere",
      teams: ["Detroit Pistons", "New York Knicks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHd9R6Rzmuhsm1nHGQDvA0Sdy1QX3Ce-VSyDqQI-S3yeRNyc_aYJkQuTL1Rr3elMNv8-59l1CohSPoHeqgc_xwauna2-fQE9o8TBu3wcIKUrgZmZYxGVK_6baiRGDLqWd_t3b-7NjiJ_OcNOw4BNXHAs=w120-h149-s-no?authuser=4"
    },
    {
      firstName: "Clyde",
      lastName: "Drexler",
      teams: ["Portland Trail Blazers", "Houston Rockets"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcGW7lPACVr1Bc41B-1_JPUgGjvM2j546R902gr_bXM57eP2qQuc8owtBQGEUtj0yC_TDRapSiBsP3xWTT8oC9NCidN2ZnMqpqjiYNF1ROsnczgDwT5eTgHARKX3FRAR6ERusA9kUP7KVDs9df_9mc9=w119-h143-s-no?authuser=4"
    },
    {
      firstName: "Tim",
      lastName: "Duncan",
      teams: ["San Antonio Spurs"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeSBt6tynUPPpa9__gtZCpkJ7oWGBIbSaPw2_g0DA-n6C6QaIHqG98QvTVaNEz2OezDgCsHRinOTKcieyIBFfH4MAib0b7EiUymWZ-HsjcG1ukA7SRuKOKzN3yFU4aLYcsSLajEmjb6SlIQAt4TKwX-=w112-h149-s-no?authuser=4"
    },
    {
      firstName: "Kevin",
      lastName: "Durant",
      teams: [
        "Oklahoma City Thunder",
        "Golden State Warriors",
        "Brooklyn Nets",
        "Phoenix Suns"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfdOso-u3HaAlC1lK_wbXpKyHhupqkVjEzu3kcMTCTF2fuda2AZLHSOiMtq3Y4wNxXR-qnh6fA6Xv6IsdLzNYYPGnj9rgx88n32qOFdCN3BZNf-BwZ-_9aEp___SGCb-w99Dytg0TTv_pswdGnEu6Wz=w99-h145-s-no?authuser=4"
    },
    {
      firstName: "Julius",
      lastName: "Erving",
      teams: ["Philadelphia 76ers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHe5zF8IcjkgSzJg3TqcXljciipz13sW72Qn2c8OgYexTVsJf1PrJLl8aEIWYd0K3Bzff1bleETnSfOnYuoIYPgmb2di6eXq_ACFBCJmK2T3TPb65yJEr6nWy2WPAu_wrEoC07sJ5BZc7DUIIt7ldaxb=w116-h154-s-no?authuser=4"
    },
    {
      firstName: "Patrick",
      lastName: "Ewing",
      teams: ["New York Knicks", "Oklahoma City Thunder", "Orlando Magic"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdGs62xfipxWEb9ZMe2WhzCCXiSeCTE8pMp4HGPvEf40pqPNAN38mMUmQI70AhQ1s7dyljNzAmQlCVxjV6x16hrqwwkCuLX_0LEW2CKl5BP8DP5CDauQ24dWT2yok18xODyeXq6dOdgnFWJkIvnfVli=w100-h154-s-no?authuser=4"
    },
    {
      firstName: "Walt",
      lastName: "Frazier",
      teams: ["New York Knicks", "Cleveland Cavaliers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHciO7yR9gvWUHAWjQnaIMT6mBo7BjNWEwviQBCFrKcPOrnfGPEMzRAvQTqKotBrXz0UN-Pc-PZO3LTR7Msdn6fLQMKNunEg3w7i-rKEIaCOMSw_fENNT7ycD2yub9KXQew4eCXL_L47aJMrsU6-Aj2k=w121-h164-s-no?authuser=4"
    },
    {
      firstName: "Kevin",
      lastName: "Garnett",
      teams: ["Minnesota Timberwolves", "Boston Celtics", "Brooklyn Nets"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHe3pcg0KHHluAV_FPbkB9YaixwpSCCL5K35IrYIuBtAx7W4AHlk5QLRFzQi5xOcjPiRVKmrIY6aWOq36sLseptwopQQMZzW18vP1RgsxxJWDKuzrqyU0EXhDitEiXvtowz2H-XBrxZnAwlKmpN-mSYD=w118-h161-s-no?authuser=4"
    },
    {
      firstName: "George",
      lastName: "Gervin",
      teams: ["San Antonio Spurs", "Chicago Bulls"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHejct7ngsmBklcmv4JxkUV2e1BzZaeUMTa9ga665EZqOn4vwRc-d4LxGQmuCH5zJfpeMsoT32dl2Zo-F2GZfxyBqlIzBL3SdzxhBUbTAf9i-5gQ3sAOx3_frMSVhR3QDDNEGg7MdpV7h0AjFfxzuhkB=w106-h161-s-no?authuser=4"
    },
    {
      firstName: "Hal",
      lastName: "Greer",
      teams: ["Philadelphia 76ers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfQT46v9OEfb_e5vNjcTnOnwUCYLy9RnhPVHxQmRqRcwGu_trrBXZRxBAxdv8gaq2XKzi8JEXmQn9mMe5lLur10plNfsj5O6JLE-mP7Z3_DtX9bHoSStKM9K_dPn_DcQbfw-jfqQZMDtZp3Z11FcTiL=w110-h139-s-no?authuser=4"
    },
    {
      firstName: "James",
      lastName: "Harden",
      teams: [
        "Oklahoma City Thunder",
        "Houston Rockets",
        "Brooklyn Nets",
        "Philadelphia 76ers"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfydfw8_Q6lphx3z2qfVpoicmE-YTyFyIEgl9q8OCCm-E92lNg96yE8aK9CULaICEEiHoiMcFeMF17LfngTmcx1IILrmfWx7aCE1LMGF4zRhIaLSfPmHmpLIXC-4ZM1hxqkj0TR81M4MVhnrKRoW1WZ=w113-h153-s-no?authuser=4"
    },
    {
      firstName: "John",
      lastName: "Havlicek",
      teams: ["Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcJ3rcJODLHzoAFimdigjFJtMp56V2e7WvO8KdCm-YZYcGH-Er2PvDO425X9xldsYPJTVU_uAi6i4O_s-qLrpYP1tx9G5qxzwfn90dmFz_tQemrchr0iFEU2hjUTjYThtNUkxfpMIxQ-rrW3-fU3wCZ=w124-h164-s-no?authuser=4"
    },
    {
      firstName: "Elvin",
      lastName: "Hayes",
      teams: ["Houston Rockets", "Washington Wizards"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcmGkSSIsgyzMOv0MzmEW99OvBcne38zCQG8hjkTjmhEVWQTtV3gaQQt23P15uOBB2UIfqUIeNJKLQD4KsdIE_iv9nqNEWkkwjOTZhq9ZrLdvKk_Aa-N6wmYbTSdRfoJoaH3xI4M4-RABGsFS0HIwk_=w111-h159-s-no?authuser=4"
    },
    {
      firstName: "Allen",
      lastName: "Iverson",
      teams: [
        "Philadelphia 76ers",
        "Denver Nuggets",
        "Detroit Pistons",
        "Memphis Grizzlies"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdMv2vXwcFGVHBfeVdgW8UMUL_fs149MYsEk-vgF5NNy2IF-afOc6PhrMa5J4-6e-wkUKWZhf83rlNpvFwS0lpnAtQx8boY-ow7Fxg8c-sJ5QySahXJg1nrbHZZd4GVu7OGYF9mQw4g68-oXJmsQWlR=w119-h153-s-no?authuser=4"
    },
    {
      firstName: "LeBron",
      lastName: "James",
      teams: ["Cleveland Cavaliers", "Miami Heat", "Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeU_qkBM6_ao90CwFdK6Uf289QIWJgT2PW_kw1ERIyjcpR6TJ_atHgDc2WKfj2_uArfdyP5rviJ3hY_9pUXdRmrFB-BQDvxmKQXP1faaKxaq3dVbDkmBzgw3QXNw50qOW7xFWwzMwGskqe94aeTf85H=w115-h143-s-no?authuser=4"
    },
    {
      firstName: "Magic",
      lastName: "Johnson",
      teams: ["Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdNaEOnL2qQBVX8Fr16CGo-oeKYlHo6KhxZ6MtiYKxFUnMrMUYbDtOVUfH0bk9cuGbC2KTRmJII7mMMlhAloHvy2qluqy2v4mPDE4CuBCk95dck-Nnb8D0xCs4ETQU5v3unL66MkzYRzShbiD3VuqOS=w108-h154-s-no?authuser=4"
    },
    {
      firstName: "Sam",
      lastName: "Jones",
      teams: ["Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfJZccD9a4u3T0OhKZEk3GA8WbiCAS20L7k3Qn9bt08lPqDZ_O4NPfkty2H818gsPzKGu4HHT4_5GPRsF6TZVve_BEsddwF7J42iRC4OMdfBzkTLZrRDOiUKIgSabrJVa5wlXKYSPzOc-fDyrZvNDcc=w118-h147-s-no?authuser=4"
    },
    {
      firstName: "Michael",
      lastName: "Jordan",
      teams: ["Chicago Bulls", "Washington Wizards"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeslDdAj67VeEBRecfNFrd_DLvRAHEHnsUJy8vN94l8nxhB5SNyrRkZw24yZTdJAmJ1HWVdAiJIoCGGI2B7umndj0u0Rok5sP7ifL_qEYSkpEKX5dgGx-TAQwgC1qLYaOuLkK4m5WHSAgrw3Ua7oHZ2=w118-h148-s-no?authuser=4"
    },
    {
      firstName: "Jason",
      lastName: "Kidd",
      teams: [
        "Dallas Mavericks",
        "Phoenix Suns",
        "Brooklyn Nets",
        "New York Knicks"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHe1N7vYqT-8Pzz9Wh_gjfBh2W0XNEpcL2XGm1q-WHD6Z_9N-SELQg9N7MiDIMGu4uGDgXe6p32R7MFaTLtwtjOusg7DwvWPtAoYbxXXnBkxrPsPDFnwg5q_D2iuCnxxzum-4rTsKXl_Yb8TM0VrH_dt=w109-h123-s-no?authuser=4"
    },
    {
      firstName: "Kawhi",
      lastName: "Leonard",
      teams: ["San Antonio Spurs", "Toronto Raptors", "Los Angeles Clippers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHe4eeNBOUvfo0GmBV2k6xqYe_iCD2Vkzk35EImONf7raIMPBpYqjr0dbMaOPvycRsQjh76xC0kp8on_zixWvcw17gq1epA4gbl2VrNrtr5UYim8XOc804cg874f51hjsE_u1BKLs86pF_koBB48pxjK=w109-h152-s-no?authuser=4"
    },
    {
      firstName: "Damian",
      lastName: "Lillard",
      teams: ["Portland Trail Blazers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcQSPzZ9kI_eQiJ9N-rGpNjLNH34bHuJ7CaFlEraPCMD7vcVAbwVPEgsZhPNzj1sl1ynGA106sydcSgk1VxaWk_FqvvToTQN2yLkOXVD542P_8eALxpEj6TT__VjZ3bzSNQU1dyEYcd_-R22Fi4d7_y=w115-h152-s-no?authuser=4"
    },
    {
      firstName: "Jerry",
      lastName: "Lucas",
      teams: ["Sacramento Kings", "Golden State Warriors", "New York Knicks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHc1wOYW6SChQClODMXBoxP-pFQUxpGSpLrgSXX2QcsTxCfCMx7akh-LI0jy3ZdEnRvbsCUooCG0J599srq322XBdck7COIOYsSsNdTq3WSAzccwV_1BXljzsSlbOStxGU0mrT7F5ruJNBLwzb6g_7oS=w117-h146-s-no?authuser=4"
    },
    {
      firstName: "Karl",
      lastName: "Malone",
      teams: ["Utah Jazz", "Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHd5eOtoz9N6aZUPiDn1x8F5KwM49Sdp12S_bsVu2BDywctzfg9mdndhEaQA1Yh3CrUD4rSArmhtOLDBebTSUdCFbUxmeca3niWnCs3HbZOz5EeS3LseqQDwempzxiUPCjM5Fb7915gNu3uNqoGxl-BC=w114-h167-s-no?authuser=4"
    },
    {
      firstName: "Moses",
      lastName: "Malone",
      teams: [
        "Los Angeles Clippers",
        "Houston Rockets",
        "Philadelphia 76ers",
        "Washington Wizards",
        "Atlanta Hawks",
        "San Antonio Spurs",
        "Milwaukee Bucks"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfNKz0O39HyjTjdZf3qIldrcxaiufB1QHnoO1yb2nKfppUEjR49UCY0FWVMAHVwGeLA5JHzuRKKQYumHahWmTQhyTzYPEuznEcoDGdKCj4LO-UuhQD35J3GnlufaqPjE53X4pKGS4vVbJkYDXFHNa-u=w112-h147-s-no?authuser=4"
    },
    {
      firstName: "Pete",
      lastName: "Maravich",
      teams: ["Atlanta Hawks", "Utah Jazz", "Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdfj4UuPPRfNk2jROcwXx1WCsT0iwBDaO4pxxPHryVTbuxINZu1G0JYc5X2o-Jr5Ywg2zw6Mx5bNL87Jv6egFbbzbZApotYsYOp8EyVhkPNh2P1_2c0ukPaWBaqLWU0Z_JkL5SfYvzeVGyLWAU_2DUG=w123-h156-s-no?authuser=4"
    },
    {
      firstName: "Bob",
      lastName: "McAdoo",
      teams: [
        "Los Angeles Clippers",
        "New York Knicks",
        "Boston Celtics",
        "Detroit Pistons",
        "Los Angeles Lakers",
        "Philadelphia 76ers",
        "Brooklyn Nets"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeDsHm5c4gLhwZh6PRJZnKumqcusAmNBMmsyCA3wd7rIly2kQQ1n1uYuknJA7Q0Ysa72IB4qBr6KN9CBBr3Edt1O7ssvbT7_4YwCFvtCEv2yergXuvufyjq7rmXbbQDwYMdgIHbP1RwyW-QEc_oHVhy=w110-h147-s-no?authuser=4"
    },
    {
      firstName: "Kevin",
      lastName: "McHale",
      teams: ["Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeUJM32YFkFq1RkJGaAuFyRlDg6_srm7GeVwcfEU1WoHSroQiEatz1wtdhxKqXyHFuJFQNqtFJKXLXPDKrobbLEAE8enqsn6hWtSc2OdkQVyqNqzwTOULcn7gSx6KnVHk1Ggi6dPQh4eAYZqjOFlv20=w122-h135-s-no?authuser=4"
    },
    {
      firstName: "George",
      lastName: "Mikan",
      teams: ["Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHehR5xyKjk9sQCwAV8IyhqTshSGcEu7tE0rDWzcr6mWeO3TIuCU2JH1HiEgG8RRiSodP3mGhBddg9SuL3O0Ohkf0GP7--OoBEl63_NlgVHf4q4h09wHbx4Z_Reb6kjin5IL3EDiu1TEe_njqBLRtluD=w118-h171-s-no?authuser=4"
    },
    {
      firstName: "Reggie",
      lastName: "Miller",
      teams: ["Indiana Pacers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcSG-ISkJihWYilIzRd9kVYVXtRX4xGFmhxdf_dYCcR1ainan6Ivx7Vax0f6szK281DlykRGKzK3AWFqslcX0Q9nZaCtQSjmN39Js8VfLpi5EpHEAEvU1YdqeX1gHbkc7QOc3ipON4EHoUthBhwdFil=w109-h136-s-no?authuser=4"
    },
    {
      firstName: "Earl",
      lastName: "Monroe",
      teams: ["Washington Wizards", "New York Knicks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHc9qyCW0D8G16JZIgeHHRXSuqmewuc4W-kxSqqdHqKR951rFUP-Iz6UsYuzeK3yTpknNyhy2FKBBN7OU6rv4EKph3ePs-v26LSSZlIv3iMXrnFbgFnlY43JYE6Mjvj4SmjlgSYBDCRDCQESr6OkU0s0=w114-h142-s-no?authuser=4"
    },
    {
      firstName: "Steve",
      lastName: "Nash",
      teams: ["Phoenix Suns", "Dallas Mavericks", "Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcXlAYTE-wK6w4QjwkoNMKx8XhHOu0y8oXlgvUkdH6spmeWgMqEmc4yJcqP4VFCa2EVXcE5KZEMK1NTwQJgoO8zxHaFoXROHSDnECrUKW-0OpkPmuJ7lGbn-f7eNUc4KwABhZ1yeotJEkOXdbfKpcAp=w118-h142-s-no?authuser=4"
    },
    {
      firstName: "Dirk",
      lastName: "Nowitzki",
      teams: ["Dallas Mavericks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHet3sSCFOq0Tnu3dNzYfZKCOaHkBsd7AwJSw5S43vz4NvvcNKmYtQEgfwfnBfqVWR_OxJyAAeK5QDKqvfHRVGK3mZ8aizRGMIWUleyErn_WD5zkrEpxWypqQWYjAXZLO_13dw9dprbttHmn6V53_Tbu=w113-h159-s-no?authuser=4"
    },
    {
      firstName: "Shaquille",
      lastName: "O'Neal",
      teams: [
        "Orlando Magic",
        "Los Angeles Lakers",
        "Miami Heat",
        "Phoenix Suns",
        "Cleveland Cavaliers",
        "Boston Celtics"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdAIEVr-7NBJQ8KyiFtXlG1J1mbKi7SeA8LK861Sx85miC4LK0RLl5ATOGDNV5Khh0aKewcoR3HUYXAhjkfLMWF9qnk4hB-wYnNIHsxBpRPuXOFnDH8swSmOA2cxaWpmYs-rE4Rnx5KlMHW9DbcgHHq=w109-h136-s-no?authuser=4"
    },
    {
      firstName: "Hakeem",
      lastName: "Olajuwon",
      teams: ["Houston Rockets", "Toronto Raptors"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeOnLuxHSAUEIIkBOTYC6KU56j4DsmoaxtxFJeb9x8o-Q5yKDQTt4KEyXRM7rCESEGiY48h6-UhdEKvKMg5ewy6xo0Q0_4D_KE4yRcQyq3tOhSivcZR_MAuxydwZ_Jsmd9V0SLjiH3wmjpv_suTwFI2=w113-h169-s-no?authuser=4"
    },
    {
      firstName: "Robert",
      lastName: "Parish",
      teams: [
        "Golden State Warriors",
        "Boston Celtics",
        "Charlotte Hornets",
        "Chicago Bulls"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfa4ru-XgkOQAd3n0F_WnrqGPtcfjrCAJt-ZnpFiMGJ1xyJ_jvoBqMeuzlqX-GNg-DxyU4_45jTTAYP-mrKFFacEd6MZBWOg9xLpWFaZkXRK8j87YlAsC3DGlZDpikSyt80PdAC3Q8a2WEgNNP7ro2i=w108-h139-s-no?authuser=4"
    },
    {
      firstName: "Chris",
      lastName: "Paul",
      teams: [
        "New Orleans Pelicans",
        "Los Angeles Clippers",
        "Houston Rockets",
        "Oklahoma City Thunder",
        "Phoenix Suns"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdLNbPkNJImDno7pm_UiWI4lK8r5KVD7MZO9NhmDgb367yi6ylcAoSvae1GnSWm40QW5I4PVA07quT9HuQT0rp8D2uxFTgOH8roM8su04Zp6nYXjxKI8gqwXgTpX_O47wfNG18_5pNKdF9UkOokEQpa=w108-h129-s-no?authuser=4"
    },
    {
      firstName: "Gary",
      lastName: "Payton",
      teams: [
        "Oklahoma City Thunder",
        "Milwaukee Bucks",
        "Los Angeles Lakers",
        "Boston Celtics",
        "Miami Heat"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeUdzRGzKO92wuIfnw2BbChtnUBdFqLoq8749nlAzZARyPyDFW3CoO1tdoV73AUp6pzqa2LqbJX4vjEgx6ROodmJ-LxlSJqQ0MD4pmQ0zqrGAiwjBOB3WWN8FRLPbjCCS3ZnE5mbjeWRhf88nJhqaCk=w109-h146-s-no?authuser=4"
    },
    {
      firstName: "Bob",
      lastName: "Pettit",
      teams: ["Atlanta Hawks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcEKt5HtdxSzKLGg4N9bnpbuVZLTLNZxj0Letkj5k5cyf1Pyibq4D68hacXm_OyFp-jEx6HWVWAJVV9UcxZ6TGLlED9lEl6XZp76Vszf3voXN9_n5l2B4294FKsygSMB6_X-b8-HpzRCrEbzUiHqOCm=w109-h138-s-no?authuser=4"
    },
    {
      firstName: "Paul",
      lastName: "Pierce",
      teams: [
        "Boston Celtics",
        "Brooklyn Nets",
        "Washington Wizards",
        "Los Angeles Clippers"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdyd_OpE_-sggyF5w6PPHWmsE_TCT4CqEW9QsXIVoNn2lX_eOmbDn80MJ49-Jssoeh_E8eTs61gmUUEjUtlM3hRbH0CoBhLRDsrGkwTun_kq-RiVrbkuZjMj4q3kp7o1CF5wW10CmDSr9n1J4mYP_vV=w111-h144-s-no?authuser=4"
    },
    {
      firstName: "Scottie",
      lastName: "Pippen",
      teams: ["Chicago Bulls", "Houston Rockets", "Portland Trail Blazers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdjsGZYMucMhRrIVqGtAZY1I_A9njLDb90SSfNX8RZIn0Fuj5fVfjWMXdQLczocrLwMMspGF7Ygl3zbY42XDLCU_QeaafPLFNim8FX1mJc8BKYrLP9nR6Qlq4sbjNQTi3djSmfAu8ROJy89QKhkdHZZ=w106-h136-s-no?authuser=4"
    },
    {
      firstName: "Willis",
      lastName: "Reed",
      teams: ["New York Knicks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdlC1hTKSru7KxZXhCVFBEwaDfAxAn0-FAu-Forr8likFgBDhN8qCXfLrqWjDXrlQebLeWeo_9IFmPLt_qHbl4WWrnLlzvlTn8TpGKkyGJ-g8jYX3H65ZwlERB6JJodPHom0hI1y1-YqyBNW7glRDCj=w105-h140-s-no?authuser=4"
    },
    {
      firstName: "Oscar",
      lastName: "Robertson",
      teams: ["Sacramento Kings", "Milwaukee Bucks"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeEZTxoRUghu1G_W5jrSaN2TNH_1Ee3xklg9-LHXi08LQs8gDe3nW2NHHI3BI4eURs37og5yELK5rncb0XeDDgnlRZPJ0sIP3kZcgeT1o5IwU7QZ13ZakWoaJeWLXhBYTxXxSDz_zWIubeD5TFOt4er=w112-h149-s-no?authuser=4"
    },
    {
      firstName: "David",
      lastName: "Robinson",
      teams: ["San Antonio Spurs"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHf6r0gO_ElxMURILUL0_rsp8kZL1-2h3DysC8YyPCXJ0ZjQwaRqMjBALa8kYlUMeOVYYJ_GOyeWHz2EeoaKrR_bFeBcViB5UBPhrZG7Ktm5yfGeDxP3LN1lbcPMZ3Ta85pg4Uy9FNXkwF5C3nw7C9FD=w113-h148-s-no?authuser=4"
    },
    {
      firstName: "Dennis",
      lastName: "Rodman",
      teams: [
        "Detroit Pistons",
        "San Antonio Spurs",
        "Chicago Bulls",
        "Los Angeles Lakers",
        "Dallas Mavericks"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHfYMn9MawlZ3YShWuuBiqUl9yQofe9MQzQScvk2F-5OIPpw_7zipkGgSamN0_dfCotdHoSzXA5bNHXSLqT1305TX-9ccjka6OD5wWqGmBV89nJexYt8pDD66OVKNIXsUkZpuQCQWKKFgj4XBNWOhSHL=w113-h133-s-no?authuser=4"
    },
    {
      firstName: "Bill",
      lastName: "Russell",
      teams: ["Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcWE8iZzWsMUOR0lQPreY3eOI5fWZcWeFYCnB61zbTeq8T_dZG32_RBQiF1hAXt-SGPZ4WojjTlYfV-KYP3IyWgeSrNF3A2imn_O2reQyWJ_ptq2NtfkACYwnaEivmY-OUuRo-vVrA5QbKFIi-vYDEw=w118-h140-s-no?authuser=4"
    },
    {
      firstName: "Dolph",
      lastName: "Schayes",
      teams: ["Philadelphia 76ers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeWJ2Gfu2lPG_F1PSAmaN2olnrenuEKkgVbh6b-e67dICuANPrWYDhujoqpQQqOHCmJipBh2bEttAAcgmLMaRl3jPZi_wEaPTibrl1eVykQbEcQikuStO8CNxlq-3Jyr2z-ETNR2tCF1EIrT0DCnBPE=w117-h128-s-no?authuser=4"
    },
    {
      firstName: "Bill",
      lastName: "Sharman",
      teams: ["Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdtGGjkwW9RtzD-Ne5ePA5eaD4xLltZRMhoYxg4XrQ_HvStxCxPnk8wCoyIOujJMNZowN2eySPkmZfFSf7_Aig-kXBJjEW07njKUZH3WE6J-L9XOvfmg_g4N46bFgiw3m9-huLTiSsQWXLEdmPO-m57=w120-h147-s-no?authuser=4"
    },
    {
      firstName: "John",
      lastName: "Stockton",
      teams: ["Utah Jazz"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHceVwYlMZbBWU542bpXZ3jqwHse2TQXlAdbOPyRzH1J5mgMAjMfrAB4C5q2i-fhnQLPqePo-4dzEOodesFm3swSU7Z7iYQouCVuzt73ZcCySO0jVO3YO6uvUGVVheQXJohZWkC_pGmbyfdtvT9gypKF=w113-h160-s-no?authuser=4"
    },
    {
      firstName: "Isiah",
      lastName: "Thomas",
      teams: ["Detroit Pistons"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHebgzMuyyFvJj9vmKV_8vaZVhXpm4MFa4oMUYIf8Q1seJdf7aDgAy1DqzEPgnE0uonpkII_ENeQTqISHzH9RDYxb4EgNb25R579emFYBgJiKXxXfZhg2wWvSIy86vcUkGXES2LK3oUJWHSA8W2ZZFGa=w121-h138-s-no?authuser=4"
    },
    {
      firstName: "Nate",
      lastName: "Thurmond",
      teams: ["Golden State Warriors", "Chicago Bulls", "Cleveland Cavaliers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHdtz9Ibo51jP4HHfrARrIRLiuDpdwSA1z_UoGiN7cl5JJXfD1BOVYo0H1F4c8DE4CZggaUSOMFQl_DLCfD0yOkstnRvFgFzyE7vujNVEgAcR27HvFudO6JBd5btTAuEg8QouKJg4uVzoVyqiaEJ3uKO=w116-h172-s-no?authuser=4"
    },
    {
      firstName: "Wes",
      lastName: "Unseld",
      teams: ["Washington Wizards"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcd2WRMUm1aZK2O7MzAEmUN5JWVNsdsKMKYAQAMUQkJZ667wP4pcQBcnEGTTAfpRAc9epaklepzWXdWfgz7w4nWNAx_XPNcG0zIknxlYIpf3InQSFkTgYNOKc9xNKLfE7g0Afkrz0Te_34Oz4loYTF_=w122-h149-s-no?authuser=4"
    },
    {
      firstName: "Dwyane",
      lastName: "Wade",
      teams: ["Miami Heat", "Chicago Bulls", "Cleveland Cavaliers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHeaoU9-xO_DlmRqBCnKulmbPRNCHo0AcL3Pu1h-GI0uY6MPTiNHHwHxwuCHBhSNn4ZpufFDCred0KQBaSyOJVBbMyKdPHNLnOrKjlxzdCZ5_Rp10jmnG0AvC9mREb4n3vu5-6BhhQ8tYfKCG6AT49lf=w109-h160-s-no?authuser=4"
    },
    {
      firstName: "Bill",
      lastName: "Walton",
      teams: ["Portland Trail Blazers", "Los Angeles Clippers", "Boston Celtics"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcMlmaVCweNlQPAn7a6DDTUP-DrN_tWTXC1vphs8-SJVFJKGWqPqZWGvX4iOFrryV0m452Y-DOjta7ZWZZt3vAVVJxV98-DoYICofMPy9ewHL1LCVgLxLAdY05fXLS0MZMaczXLeBb3YvTbsHt0hpo0=w115-h118-s-no?authuser=4"
    },
    {
      firstName: "Jerry",
      lastName: "West",
      teams: ["Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcSAUH808YE411xBiQfCMRU8qYKjFE0J5Wch3z6_XocFbBjNCJjIc6065CrcpbLuSfw4yP3J42ZZvdHSOUyb5YbSWerAPf3aIpXj34Ll6Gzk8J21M6ySSP6JexO4_ktlhgQfgQWduEbRfRHHVS9IdcB=w106-h137-s-no?authuser=4"
    },
    {
      firstName: "Russell",
      lastName: "Westbrook",
      teams: [
        "Oklahoma City Thunder",
        "Houston Rockets",
        "Washington Wizards",
        "Los Angeles Lakers",
        "Los Angeles Clippers"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHd7Eeldz5COIN6hnvQjB_h1BnCboMuifVJeaFl6zSGMyGnZdSTH8vtQ3ciuAvuS5gXclThilPHIVXO0OKWNa2YUzD0vZAQgRPKn7yijIRYyuNiyquY-dwKXlNvGEgo-jAucozhkbJ3xunyh6RYQvPJm=w104-h130-s-no?authuser=4"
    },
    {
      firstName: "Lenny",
      lastName: "Wilkens",
      teams: [
        "Atlanta Hawks",
        "Oklahoma City Thunder",
        "Cleveland Cavaliers",
        "Portland Trail Blazers"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHcqhl906VOxb0-Q-olZlayK7_Fom0UmDbnh4z06aOMjGdJKvvn-H97D3KljzOkZzo_AG0Jra72DrreOwq2pwjrTGnEQ-12Z5zEFNoFX9lwBumh6IjWPNdALpfXauFxCca3iY5z5RrVM9zTOo7GD81rE=w121-h157-s-no?authuser=4"
    },
    {
      firstName: "Dominique",
      lastName: "Wilkins",
      teams: [
        "Atlanta Hawks",
        "Los Angeles Clippers",
        "Boston Celtics",
        "San Antonio Spurs",
        "Orlando Magic"
      ],
      img: "https://lh3.googleusercontent.com/pw/ADCreHf2NCiXICdTHxbT3WRQ0TGyJu7xvnNUC1mYk2T_L-5jHe0l93cgtGf7MsFLtjnNJ9F80Usnb1xKhE99fOm0Swr5WOewkbKGnaI-oMmbPio57roHn9b2m3Cl9W4Pmrh7oPx7ltTpPn45-YkMVg8bJCLR=w119-h137-s-no?authuser=4"
    },
    {
      firstName: "James",
      lastName: "Worthy",
      teams: ["Los Angeles Lakers"],
      img: "https://lh3.googleusercontent.com/pw/ADCreHd7RsM8QZ5JbSNL-kCPkZW47OjJbFaaSQns-mjujeGKYFDjMJdYacpw-iUA-laOckd9TPF4UlGi97hnKSjE_1dbnXssKPOogWvzm3OEA6PwoBTKc9_oWMNvi6bV_TpRvWybQ0dWU9zttBO1yFBjcPz9=w112-h150-s-no?authuser=4"
    }
  ];